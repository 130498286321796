// SnackbarContext.js
import React, { createContext, useContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Stack } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackBarSeverity] = useState("error");
  const [snackbarMsgArray, setSnackbarMsgArray] = useState([]);

  const openSnackbar = (message, severity = "error") => {
    setSnackbarMsgArray([])
    if (typeof message === "object") {
      setSnackbarMsgArray(message);
    }
    if (typeof message === "string") {
      setSnackbarMessage(message);
    }
    setSnackbarOpen(true);
    setSnackBarSeverity(severity);
  };
  const renderSnackbar = (msg, i) => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={9000}
        onClose={closeSnackbar}
        key={`snack-${i + 1}`}
      >
        <Alert
          data-testid="snackbar-alert-test"
          onClose={closeSnackbar}
          severity={msg.severity || snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {msg.message || msg}
        </Alert>
      </Snackbar>
    );
  };
  const closeSnackbar = () => {
    setSnackbarMessage("");
    setSnackbarMsgArray([]);
    setSnackbarOpen(false);
  };
  return (
    <SnackbarContext.Provider value={openSnackbar}>
      {children}
      <div>
      {snackbarMsgArray.length > 0 ? (
        <Stack direction={"column"}  spacing={8} sx={{ maxWidth: 800 , height: "100%"}}>{snackbarMsgArray.map((msg , i) => renderSnackbar(msg , i))}</Stack>
      ) : (
        renderSnackbar(snackbarMessage)
      )}
      </div>

    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);
