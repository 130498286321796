import React, { useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import * as api from "../../../api";
import { useSnackbar } from "../../../context/SnackbarContext";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

const SelectFiles = ({
  onSelection,
  selectMultiple = true,
  queryType = null,
}) => {
  // Define your state for each form input and for the file list
  const [fileList, setFileList] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const openSnackbar = useSnackbar();

  const fileColumns = [
    {
      accessorKey: "crm_link",
      header: "CRM Link",
      size: 50,
      Cell: ({ cell }) => (
          <Button
              variant="contained"
              color="primary"
              onClick={() => window.open(cell.getValue(), '_blank', 'noopener,noreferrer')}>
            View
          </Button>
      ),
    },
    {
      accessorKey: "id",
      header: "id",
      size: 50,
    },
    {
      accessorKey: "name",
      header: "File Name",
      size: 50,
    },
    {
      accessorKey: "type",
      header: "File Type",
      size: 50,
    },
    {
      accessorKey: "reporting_month",
      header: "Reporting Month",
      size: 50,
    },
    {
      accessorKey: "reporting_year",
      header: "Reporting Year",
      size: 50,
    },
  ];

  const handleSelection = (newValue) => {
    setRowSelection(newValue);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const selectedDates = fileList
      .map((file) => {
        if (
          Object.keys(rowSelection).find(
            (key) => file.id.toString() === key,
          ) !== undefined
        ) {
          return {
            reporting_year: file.reporting_year,
            reporting_month: file.reporting_month,
          };
        }
        return null;
      })
      .filter((date) => date !== null);
    onSelection(rowSelection, selectedDates);
  }, [rowSelection]);

  useEffect(() => {
    // Fetch file list
    const fetchFileList = async () => {
      const response = await api.fetchDocumentWithFileType(
        queryType.companyId,
        queryType.fileType,
      );
      if (typeof response === "string") {
        openSnackbar(response);
        return;
      }

      const docs = response.documents?.map((f) => ({
        id: f.id,
        name: f.file,
        type: f.document_type_id,
        reporting_year: f.referred_year,
        crm_link: `${process.env.REACT_APP_CSP_BASE_URL}/companies/${f.parent_id}/documents/${f.id}/download/${f.file}`,
        reporting_month: f.referred_month,
      }));
      setFileList(docs);
    };
    fetchFileList();
  }, []);
console.log(fileColumns, fileList)
  return (
    <div>
      <MaterialReactTable
        columns={fileColumns}
        data={fileList}
        enableRowSelection
        onRowSelectionChange={handleSelection} //connect internal row selection state to your own
        state={{ rowSelection }} //pass our managed row selection state to the table to use
        getRowId={(originalRow) => originalRow.id}
        muiTableBodyRowProps={({ row }) => ({
          onClick: row.getToggleSelectedHandler(),
          sx: { cursor: "pointer" },
        })}
        initialState={{
          sorting: [
            {
              id: "id", //sort by age by default on page load
              desc: true,
            },
          ],
        }}
        enableMultiRowSelection={selectMultiple}
      />
    </div>
  );
};

export default SelectFiles;

SelectFiles.propTypes = {
    onSelection: PropTypes.func.isRequired,
    selectMultiple: PropTypes.bool,
    queryType: PropTypes.shape({
        companyId: PropTypes.number.isRequired,
        fileType: PropTypes.number.isRequired,
    }),
};
