import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { renderHeadline } from "../FinalOpinionPage";
import { useLocation } from "react-router-dom";
import {
  fetchLoanConditionForCP,
  postLoanConditionForCP,
  updateLoadConditionForCP,
  deleteLoanConditionForCP,
} from "../../../api";
import FullFeaturedCrudGrid from "../../../utils/FullFeaturedCrudGrid/FullFeaturedCrudGrid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { toast } from "sonner";
import { dateColumnType } from "../../../utils/FullFeaturedCrudGrid/DatePickerUtilsForCell";

// MAIN COMPONENT - LoanConditionTableComponent - This component is used to display the loan conditions table
const LoanConditionTableComponent = ({
  title,
  selectedCategory,
  loanConditionUtilsProvider,
}) => {
  // State variables
  const category = loanConditionUtilsProvider?.getCategory(selectedCategory);
  const isCovenants = category === "Covenants";
  const location = useLocation();
  const [loanConditions, setLoanConditions] = useState([]);
  const [creditProjectId, setCreditProjectId] = useState(null);
  const matches = useMediaQuery("(max-width:1500px)");

  // This function is used to transform the data to grid format rows
  const transformToGridFormat = React.useCallback(
    (data) => {
      const filteredData = data.filter((item) => item.category === category);
      return filteredData.map((item) => {
        const row = {
          id: item.id,
          condition: loanConditionUtilsProvider.loanConditionPositions?.[
            String(selectedCategory)
          ].find((it) => it.value === item.condition)?.description,
          description: item.details,
          category: item.category,
          start_date: new Date(item.meta_data?.start_date) || new Date(),
          rotation_period:
            loanConditionUtilsProvider.covenantsRotationPosition.find(
              (it) => it.value === item.meta_data?.rotation_period
            )?.description || "",
        };
        return row;
      });
    },
    [
      category,
      loanConditionUtilsProvider.covenantsRotationPosition,
      loanConditionUtilsProvider.loanConditionPositions,
      selectedCategory,
    ]
  );
  // Side effect to fetch the loan conditions for the credit project
  useEffect(() => {
    // Get the credit project id from the url
    const searchParams = new URLSearchParams(location.search);
    const creditProjectId = searchParams.get("credit_project_id");
    if (creditProjectId) {
      // Fetch the loan conditions for the credit project
      fetchLoanConditionForCP(creditProjectId).then((res) => {
        setLoanConditions(transformToGridFormat(res));
      });
      setCreditProjectId(creditProjectId);
    }
  }, [category, location.search, selectedCategory, transformToGridFormat]);

  // This function is used to create a new row data
  const createRowData = (rows) => {
    const newId =
      rows.length > 0
        ? Math.max(...rows.map((r) => (r.id ? r.id : 0) * 1)) + 1
        : 1;
    return {
      id: newId,
      condition: "",
      description: "",
    };
  };
  // This function is used to do actions on saving the row
  const onSaveRow = (id, updatedRow, oldRow, internalRows) => {
    postLoanConditionForCP(creditProjectId, {
      category,
      condition: loanConditionUtilsProvider.loanConditionPositions[
        String(selectedCategory)
      ]?.find((item) => item.description === updatedRow.condition).value,
      details: updatedRow.description,
      meta_data: isCovenants
        ? {
            start_date: updatedRow.start_date
              ? new Date(updatedRow.start_date).toISOString().slice(0, 10)
              : new Date().toISOString().slice(0, 10),
            rotation_period:
              loanConditionUtilsProvider.covenantsRotationPosition.find(
                (item) => item.description === updatedRow.rotation_period
              ).value,
          }
        : null,
    })
      .then((res) => {
        toast.success(`Saved Successfully!`);
        fetchLoanConditionForCP(creditProjectId).then((res) => {
          setLoanConditions(transformToGridFormat(res));
        });
      })
      .catch((_) => {
        toast.error(`Error on saving!`);
      });
  };
  // This function is used to do actions on updating the row
  const onUpdateRow = (id, updatedRow, oldRow, internalRows) => {
    updateLoadConditionForCP(id, {
      category,
      condition: loanConditionUtilsProvider.loanConditionPositions[
        String(selectedCategory)
      ]?.find((item) => item.description === updatedRow.condition).value,
      details: updatedRow.description,
      meta_data: isCovenants
        ? {
            start_date: updatedRow.start_date
              ? new Date(updatedRow.start_date).toISOString().slice(0, 10)
              : new Date().toISOString().slice(0, 10),
            rotation_period:
              loanConditionUtilsProvider.covenantsRotationPosition.find(
                (item) => item.description === updatedRow.rotation_period
              ).value,
          }
        : null,
    })
      .then((_) => {
        toast.success(`Updated Successfully!`);
      })
      .catch((_) => {
        toast.error(`Error on update!`);
      });
  };
  // This function is used to do actions on deleting the row
  const onDeleteRow = (id) => {
    deleteLoanConditionForCP(id)
      .then((_) => {
        toast.success(`Deleted Successfully!`);
      })
      .catch((_) => {
        toast.error(`Error on delete!`);
      });
  };
  // Columns for the DataGrid
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "condition",
      headerName: loanConditionUtilsProvider.getConditionColumnHeader(category),
      renderHeader: (params) => {
        return <strong> {params.colDef.headerName}</strong>;
      },
      flex: isCovenants ? 0.25 : 0.4,
      type: "singleSelect",
      valueOptions:
        loanConditionUtilsProvider.loanConditionPositions?.[
          String(selectedCategory)
        ]?.map((item) => item.description) || [],
      editable: true,
    },
    {
      field: "description",
      headerName: isCovenants ? "Details" : "Further Details:",
      flex: isCovenants ? 0.25 : 0.4,
      editable: true,
      renderHeader: (params) => {
        return <strong> {params.colDef.headerName}</strong>;
      },
    },
  ];
  // Extra columns for the covenants category
  const extraColumnsForCovenants = [
    {
      field: "start_date",
      ...dateColumnType,
      type: "date",
      headerName: "Starting From:",
      flex: 0.2,
      editable: true,
      renderHeader: (params) => {
        return <strong> {params.colDef.headerName}</strong>;
      },
    },
    {
      field: "rotation_period",
      headerName: "Covenants Rotation:",
      flex: 0.2,
      type: "singleSelect",
      valueOptions:
        loanConditionUtilsProvider.covenantsRotationPosition.map(
          (item) => item.description
        ) || [],
      editable: true,
      renderHeader: (params) => {
        return <strong> {params.colDef.headerName}</strong>;
      },
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "2rem 0rem",
      }}
    >
      {/* Header for Grid Table */}
      {renderHeadline(title)}
      {/* Grid Table */}
      <Box sx={{ width: matches ? "1200px" : "80vw" }}>
        <FullFeaturedCrudGrid
          rows={loanConditions}
          columns={
            isCovenants ? [...columns, ...extraColumnsForCovenants] : columns
          }
          onSaveRow={onSaveRow}
          onUpdateRow={onUpdateRow}
          onDeleteRow={onDeleteRow}
          createRowData={createRowData}
          color={"black"}
          renderBoldActionHeader
        />
      </Box>
    </Box>
  );
};
LoanConditionTableComponent.propTypes = {
  title: PropTypes.string.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  loanConditionUtilsProvider: PropTypes.shape({
    getCategory: PropTypes.func.isRequired,
    loanConditionPositions: PropTypes.object.isRequired,
    covenantsRotationPosition: PropTypes.array.isRequired,
    getConditionColumnHeader: PropTypes.func.isRequired,
  }).isRequired,
};
export default LoanConditionTableComponent;
