import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { MessageComponent } from "./MessageComponent";
import { useLocation } from "react-router";
import { fetchFinalOpinion } from "../../api";
import { toast } from "sonner";
import { DemoMessageComponent } from "./DemoMessageComponent";
import CircularProgress from "@mui/material/CircularProgress";
import { useFetchPositionsForLoanCondition } from "../../hooks";
import LoanConditionsWrapper from "./LoanConditions/LoanConditionsWrapper";
import {useParams} from "react-router-dom";

export const renderHeadline = (title) => {
  return (
    <Box
      sx={{
        textAlign: "center",
        padding: "6px",
        width: "100%",
        fontSize: "19px",
        fontWeight: "normal",
        color: "white",
        backgroundColor: "#3c3c3c",
      }}
    >
      {title}
    </Box>
  );
};


const FinalOpinionPage = () => {

  const [companyId, setCompanyId] = useState(null);
  const [creditProjectId, setCreditProjectId] = useState(null);
  const [finalOpinionData, setFinalOpinionData] = useState({});
  const [loadingFinalOpinionData, setLoadingFinalOpinionData] = useState(true);
  const [pageErrorMessage, setPageErrorMessage] = useState("");
  const [currentlyFocusedKey, setCurrentlyFocusedKey] = useState("");
  let location = useLocation();
  const { data: loanConditionPositions } = useFetchPositionsForLoanCondition();

  useEffect(() => {
    // Extract creditProjectId from query params
    const params = new URLSearchParams(location.search);
    const extractedCreditProjectId = params.get("credit_project_id");
    if (extractedCreditProjectId !== null) {
      setCreditProjectId(extractedCreditProjectId);
    }
    // Get the company ID from the URL Path
    const match = /\/company\/(\d+)/.exec(location.pathname);
    if (match && match[1]) {
      const extractedCompanyId = parseInt(match[1], 10);
      setCompanyId(extractedCompanyId);
    }
  }, [location]);

  useEffect(() => {
    if (creditProjectId) {
      setLoadingFinalOpinionData(true);
      // Fetch the company data
      fetchFinalOpinion(creditProjectId).then((resp) => {
        if (typeof resp === "string") {
          toast.error("Loading Final Opinion Failed: " + resp);
          setPageErrorMessage("Loading Final Opinion Failed: " + resp);
          setLoadingFinalOpinionData(false);
        } else {
          setFinalOpinionData(resp);
          setLoadingFinalOpinionData(false);
          toast.success("Final Opinion Loaded");
        }
      });
    }
  }, [creditProjectId]);

  const renderTitle = (title) => {
    return (
      <Box
        sx={{
          textAlign: "center",
          padding: "10px",
          width: "100%",
          fontSize: "22px",
          fontWeight: "bold",
          color: "white",
          backgroundColor: "#3c3c3c",
        }}
      >
        {title}
      </Box>
    );
  };

  const renderSubHeadline = (title) => {
    return (
      <Box
        sx={{
          paddingTop: "10px",
          paddingBottom: "10px",
          width: "100%",
          fontSize: "17px",
          fontWeight: "bold",
        }}
      >
        {title}
      </Box>
    );
  };

  const sectionStyle = { paddingBottom: "30px" };
  if (
    !creditProjectId ||
    creditProjectId === "" ||
    creditProjectId === "undefined"
  ) {
    return (
      <>
        Credit Project ID not found yet. Please select a company in the search
        tool, or create a Credit Project for the company
      </>
    );
  }
  if (pageErrorMessage !== "") {
    return <Box data-testid="Error Message">{pageErrorMessage}</Box>;
  }
  if (loadingFinalOpinionData) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" sx={{ color: "gray", marginBottom: "1rem" }}>
          Loading Final Opinion Page
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
          Please wait...
        </Typography>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box sx={{ paddingRight: "15px" }}>
      {renderTitle("Final Opinion")}
      <Box sx={sectionStyle}></Box>

      <Box sx={sectionStyle}>
        {renderHeadline("III. SUMMARY CREDIT DECISION/ FAZIT:")}
        {Object.keys(
          finalOpinionData.groups.SummaryCreditDecision.data_points
        ).map((key, idx) => {
          return (
            <MessageComponent
              keyData={
                finalOpinionData.groups.SummaryCreditDecision.data_points[key]
              }
              keyName={key}
              companyId={companyId}
              creditProjectId={creditProjectId}
              currentlyFocusedKey={currentlyFocusedKey}
              setCurrentlyFocusedKey={setCurrentlyFocusedKey}
              key={idx}
            />
          );
        })}
      </Box>
      <Box sx={sectionStyle}>
        {renderHeadline("IV. BUSINESS MODEL DESCRIPTION:")}
        {renderSubHeadline(
          "Client's Business Model, History, Strategy, Customers and Suppliers:"
        )}
        {Object.keys(
          finalOpinionData.groups.ClientsBusinessModel.data_points
        ).map((key, idx) => {
          return (
            <MessageComponent
              keyData={
                finalOpinionData.groups.ClientsBusinessModel.data_points[key]
              }
              keyName={key}
              companyId={companyId}
              creditProjectId={creditProjectId}
              currentlyFocusedKey={currentlyFocusedKey}
              setCurrentlyFocusedKey={setCurrentlyFocusedKey}
              key={idx}
            />
          );
        })}
        {renderSubHeadline("Market Description and Competitive Position:")}
        {Object.keys(finalOpinionData.groups.MarketDescription.data_points).map(
          (key, idx) => {
            return (
              <MessageComponent
                keyData={
                  finalOpinionData.groups.MarketDescription.data_points[key]
                }
                keyName={key}
                companyId={companyId}
                creditProjectId={creditProjectId}
                currentlyFocusedKey={currentlyFocusedKey}
                setCurrentlyFocusedKey={setCurrentlyFocusedKey}
                key={idx}
              />
            );
          }
        )}
        {renderSubHeadline(
          "Ownership Structure of the Borrower and Group of Connected Companies:"
        )}
        {Object.keys(
          finalOpinionData.groups.OwnershipStructure.data_points
        ).map((key, idx) => {
          return (
            <MessageComponent
              keyData={
                finalOpinionData.groups.OwnershipStructure.data_points[key]
              }
              keyName={key}
              companyId={companyId}
              creditProjectId={creditProjectId}
              currentlyFocusedKey={currentlyFocusedKey}
              setCurrentlyFocusedKey={setCurrentlyFocusedKey}
              key={idx}
            />
          );
        })}
        {renderSubHeadline("Management:")}
        {Object.keys(finalOpinionData.groups.Management.data_points).map(
          (key, idx) => {
            return (
              <MessageComponent
                keyData={finalOpinionData.groups.Management.data_points[key]}
                keyName={key}
                companyId={companyId}
                creditProjectId={creditProjectId}
                currentlyFocusedKey={currentlyFocusedKey}
                setCurrentlyFocusedKey={setCurrentlyFocusedKey}
                key={idx}
              />
            );
          }
        )}
      </Box>
      <Box sx={sectionStyle}>
        {renderHeadline("V. FINANCIAL ANALYSIS:")}
        {renderSubHeadline(
          "Assessment of Sales and Earnings - Historic, of Current Trading and Business Forecast:"
        )}
        {Object.keys(
          finalOpinionData.groups.AssessmentOfSalesAndEarnings.data_points
        ).map((key, idx) => {
          return (
            <MessageComponent
              keyData={
                finalOpinionData.groups.AssessmentOfSalesAndEarnings
                  .data_points[key]
              }
              keyName={key}
              companyId={companyId}
              creditProjectId={creditProjectId}
              currentlyFocusedKey={currentlyFocusedKey}
              setCurrentlyFocusedKey={setCurrentlyFocusedKey}
              key={idx}
            />
          );
        })}
        {renderSubHeadline(
          "Assessment of Balance Sheet Structure - Historic, of Current Trading and Business Forecast:"
        )}
        {Object.keys(
          finalOpinionData.groups.AssessmentOfBalanceSheetStructure.data_points
        ).map((key, idx) => {
          return (
            <MessageComponent
              keyData={
                finalOpinionData.groups.AssessmentOfBalanceSheetStructure
                  .data_points[key]
              }
              keyName={key}
              companyId={companyId}
              creditProjectId={creditProjectId}
              currentlyFocusedKey={currentlyFocusedKey}
              setCurrentlyFocusedKey={setCurrentlyFocusedKey}
              key={idx}
            />
          );
        })}
        {renderSubHeadline(
          "Liquidity Situation, Financial Liabilities, Level and Debt Service Capability:"
        )}
        {Object.keys(
          finalOpinionData.groups.LiquiditySituation.data_points
        ).map((key, idx) => {
          return (
            <MessageComponent
              keyData={
                finalOpinionData.groups.LiquiditySituation.data_points[key]
              }
              keyName={key}
              companyId={companyId}
              creditProjectId={creditProjectId}
              currentlyFocusedKey={currentlyFocusedKey}
              setCurrentlyFocusedKey={setCurrentlyFocusedKey}
              key={idx}
            />
          );
        })}
      </Box>
      <Box sx={sectionStyle}>
        {renderHeadline("VI. SWOT-ANALYSIS:")}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            marginTop: "10px",
          }}
        >
          <Box sx={{ display: "flex", gap: "15px" }}>
            <Box sx={{ width: "50%" }}>
              <MessageComponent
                keyData={finalOpinionData.data_points["SWOT_Strengths"]}
                keyName="SWOT_Strengths"
                companyId={companyId}
                creditProjectId={creditProjectId}
                currentlyFocusedKey={currentlyFocusedKey}
                setCurrentlyFocusedKey={setCurrentlyFocusedKey}
              />
            </Box>
            <Box sx={{ width: "50%" }}>
              <MessageComponent
                keyData={finalOpinionData.data_points["SWOT_Weaknesses"]}
                keyName="SWOT_Weaknesses"
                companyId={companyId}
                creditProjectId={creditProjectId}
                currentlyFocusedKey={currentlyFocusedKey}
                setCurrentlyFocusedKey={setCurrentlyFocusedKey}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "15px" }}>
            <Box sx={{ width: "50%" }}>
              <MessageComponent
                keyData={finalOpinionData.data_points["SWOT_Opportunities"]}
                keyName="SWOT_Opportunities"
                companyId={companyId}
                creditProjectId={creditProjectId}
                currentlyFocusedKey={currentlyFocusedKey}
                setCurrentlyFocusedKey={setCurrentlyFocusedKey}
              />
            </Box>
            <Box sx={{ width: "50%" }}>
              <MessageComponent
                keyData={finalOpinionData.data_points["SWOT_Threats"]}
                keyName="SWOT_Threats"
                companyId={companyId}
                creditProjectId={creditProjectId}
                currentlyFocusedKey={currentlyFocusedKey}
                setCurrentlyFocusedKey={setCurrentlyFocusedKey}
              />
            </Box>
          </Box>
        </Box>
        {renderSubHeadline("Generelle Risiken und Mitigants:")}
        {Object.keys(
          finalOpinionData.groups.SWOT_GenerelleRisiken.data_points
        ).map((key, idx) => {
          return (
            <MessageComponent
              keyData={
                finalOpinionData.groups.SWOT_GenerelleRisiken.data_points[key]
              }
              keyName={key}
              companyId={companyId}
              creditProjectId={creditProjectId}
              currentlyFocusedKey={currentlyFocusedKey}
              setCurrentlyFocusedKey={setCurrentlyFocusedKey}
              key={idx}
            />
          );
        })}
      </Box>
      <Box sx={sectionStyle}>
        {renderHeadline("VII. DEMO COMPONENTS:")}
        {renderSubHeadline("'bubble' Demo Components:")}
        <DemoMessageComponent
          keyData={{
            data_point_id: 58,
            content: null,
            number: null,
            hidden: false,
            type: "noname-message",
            name: "'bubble' Feld 1",
            description: null,
          }}
          keyName="Demo_id-1"
          design="bubble"
        />
        <DemoMessageComponent
          keyData={{
            data_point_id: 58,
            content: null,
            number: null,
            hidden: false,
            type: "message",
            name: "'bubble' Feld 2",
            description: null,
          }}
          keyName="Demo_id-2"
          design="bubble"
        />
        <DemoMessageComponent
          keyData={{
            data_point_id: 58,
            content: null,
            number: null,
            hidden: false,
            type: "conditional-message",
            name: "Optionales 'bubble' Feld 3",
            description: "- description /n - with information about the field",
          }}
          keyName="Demo_id-3"
          design="bubble"
        />
        {renderSubHeadline("'snow' Demo Components:")}
        <DemoMessageComponent
          keyData={{
            data_point_id: 58,
            content: null,
            number: null,
            hidden: false,
            type: "noname-message",
            name: "'snow' Feld 1",
            description: null,
          }}
          keyName="Demo_id-4"
          design="snow"
        />
        <DemoMessageComponent
          keyData={{
            data_point_id: 58,
            content: null,
            number: null,
            hidden: false,
            type: "message",
            name: "'snow' Feld 2",
            description: null,
          }}
          keyName="Demo_id-5"
          design="snow"
        />
        <DemoMessageComponent
          keyData={{
            data_point_id: 58,
            content: null,
            number: null,
            hidden: false,
            type: "conditional-message",
            name: "Optionales 'snow' Feld 3",
            description: "- description /n - with information about the field",
          }}
          keyName="Demo_id-6"
          design="snow"
        />
      </Box>
      {/* LOAN CONDITION TABLES */}
      {loanConditionPositions && (
        <LoanConditionsWrapper
          loanConditionPositions={loanConditionPositions}
        />
      )}
    </Box>
  );
};

export default FinalOpinionPage;
