import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import LoanConditionTableComponent from "./LoanConditionTableComponent";
import LoanConditionUtilsProvider from "./LoanConditionUtils";

const LoanConditionsWrapper = ({ loanConditionPositions }) => {
  const loanConditionUtilsProvider = new LoanConditionUtilsProvider(
    loanConditionPositions
  );
  return (
    <Box data-testId="loan-condition-FO-wrapper">
      <LoanConditionTableComponent
        title={"VIII. LOAN CONDITIONS"}
        selectedCategory={"LoanRepaymentEnum"}
        loanConditionPositions={loanConditionPositions}
        loanConditionUtilsProvider={loanConditionUtilsProvider}
      />

      <LoanConditionTableComponent
        title={"IX. DISBURSEMENT REQUIREMENTS"}
        selectedCategory={"DisbursementRequirementsEnum"}
        loanConditionPositions={loanConditionPositions}
        loanConditionUtilsProvider={loanConditionUtilsProvider}
      />

      <LoanConditionTableComponent
        title={"X. COVENANTS"}
        selectedCategory={"CovenantsEnum"}
        loanConditionPositions={loanConditionPositions}
        loanConditionUtilsProvider={loanConditionUtilsProvider}
      />

      <LoanConditionTableComponent
        title={"XI. INVESTOR PROTECTION"}
        selectedCategory={"InvestorProtectionEnum"}
        loanConditionPositions={loanConditionPositions}
        loanConditionUtilsProvider={loanConditionUtilsProvider}
      />
    </Box>
  );
};

LoanConditionsWrapper.propTypes = {
  loanConditionPositions: PropTypes.object.isRequired,
};

export default LoanConditionsWrapper;
