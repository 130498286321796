const stepsOption1 = [
  { id: "select_mode", name: "Select Mode" },
  { id: "1_select_file", name: "Select File" },
  { id: "1_select_date", name: "Select Date" },
];
const stepsOption2 = [
  { id: "select_mode", name: "Select Mode" },
  // For hgb csv no date selection options will be there
  { id: "2_select_files", name: "Select Files" },
];
const stepsOption3 = [
  { id: "select_mode", name: "Select Mode" },
  { id: "3_select_file", name: "Select File" },
  { id: "3_select_date", name: "Select Date" },
];
const stepsOption4 = [
  { id: "select_mode", name: "Select Mode" },
  { id: "4_select_files", name: "Select Files" },
  { id: "4_select_date", name: "Select Date" },
];

export const getSteps = (selectedOption) => {
  switch (selectedOption) {
    case "option1":
      return stepsOption1;
    case "option2":
      return stepsOption2;
    case "option3":
      return stepsOption3;
    case "option4":
      return stepsOption4;
    default:
      return [];
  }
};

export const proformaSteps = [
  { id: "select_data", name: "Select Date" },
  { id: "edit_proforma", name: "Edit Proforma" },
];
