import React, {useEffect, useMemo, useState} from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {postForecastScenario, updateForecastScenario} from "../../api";
import {toast} from "sonner";
import PropTypes from "prop-types";

export const ForecastScenarioDialog = ({open, handleClose, timeSliceId, isEditMode, existingScenario}) => {
    const queryClient = useQueryClient();

    const defaultScenario = useMemo(() => ({
        name: '',
        description: '',
        time_slice_id: parseInt(timeSliceId, 10),
        type: 'stress', // Default type
        number_of_years: 1, // Default number of years
        copy_from_base: false,
    }), [timeSliceId]);

    const [scenario, setScenario] = useState(defaultScenario);
    // Set the initial state when the component mounts or when the props change
    useEffect(() => {
        if (isEditMode && existingScenario) {
            setScenario(existingScenario);
        } else {
            setScenario(defaultScenario);
        }
    }, [isEditMode, existingScenario, defaultScenario]);

    // Mutation for posting/updating the scenario
    const scenarioMutation = useMutation({
        mutationFn: isEditMode ? () => updateForecastScenario(existingScenario.id, scenario) : () => postForecastScenario(scenario),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['forecastScenarios', timeSliceId]});
            handleClose();
            setScenario(defaultScenario); // Reset the state
            isEditMode ? toast.success("Scenario successfully updated") : toast.success("Scenario successfully created")
        },
        onError: (error) => {
            toast.error(error.response.data.detail);
        }
    });

    const handleSubmit = () => {
        scenarioMutation.mutate();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create New Forecast Scenario</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    label="Name"
                    fullWidth
                    value={scenario.name}
                    onChange={(e) => setScenario({...scenario, name: e.target.value})}
                />
                <TextField
                    margin="dense"
                    label="Description"
                    fullWidth
                    multiline
                    rows={4}
                    value={scenario.description}
                    onChange={(e) => setScenario({...scenario, description: e.target.value})}
                />
                <TextField
                    margin="dense"
                    label="Number of Years"
                    type="number"
                    fullWidth
                    InputProps={{inputProps: {min: 1, max: 5}}}
                    value={scenario.number_of_years}
                    onChange={(e) => setScenario({...scenario, number_of_years: parseInt(e.target.value, 10)})}
                />
                <Select
                    value={scenario.type}
                    data-testid={'forecast-scenario-dialog-type'}
                    onChange={(e) => setScenario({...scenario, type: e.target.value})}
                    fullWidth
                    disabled={isEditMode}
                >
                    <MenuItem value="base">Base</MenuItem>
                    <MenuItem value="stress">Stress</MenuItem>
                </Select>
                {scenario.type === 'stress' && (
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={scenario.copy_from_base}
                                    onChange={(event) => {
                                        setScenario({...scenario, copy_from_base: event.target.checked});
                                    }}
                                />
                            }
                            label="Werte vom Basisszenario kopieren"
                        />
                        <Typography variant="body2" color="textSecondary">
                            Achtung: Dies wird alle Positionen im Stressszenario überschreiben.
                        </Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit} color="primary" variant='contained'
                        disabled={scenario.name === ''}>{isEditMode ? 'Update' : 'Create'}</Button>
            </DialogActions>
        </Dialog>
    );
};

ForecastScenarioDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    timeSliceId: PropTypes.number.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    existingScenario: PropTypes.object
}
