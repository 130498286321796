import React from 'react';
import { Select, MenuItem } from '@mui/material';
import PropTypes from "prop-types";

const DDCallSelector = ({ selectedDdCallId, handleSelectChange, ddCalls }) => {
    return (
        <Select
            value={selectedDdCallId}
            onChange={handleSelectChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{ mr: 2 }} // Add right margin for spacing
        >
            <MenuItem value="" disabled label={"choose DD Call"}>DD Call auswählen</MenuItem>
            {ddCalls.map((ddCall) => (
                <MenuItem key={ddCall.id} value={ddCall.id}>{ddCall.name}</MenuItem>
            ))}
            <MenuItem value="new" style={{ fontWeight: 'bold', color: 'green' }}>+ Neuer DD Call</MenuItem>
        </Select>
    );
};

export default DDCallSelector;

DDCallSelector.propTypes = {
    selectedDdCallId: PropTypes.string.isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    ddCalls: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
}
