import { Box, Typography } from "@mui/material";
import React from "react";
import EditDataGrid from "./EditDataGrid";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputDataGridFormatterClient from "../../utils/InputDataGridFormatterClient/InputDataGridFormatterClient";
import { useFetchAllComments, useFetchPositionAttributes } from "../../hooks";
import { toast } from "sonner";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

// MAIN COMPONENT -  HGB INPUT DATA GRID WRAPPER
function EditDataGridWrapper(props) {
  // Check if the screen size is less than 1500px
  const matches = useMediaQuery("(max-width:1500px)");
  // Check if the position type is BILANZESUMME
  const isApSum = props.positionType === "ap_sum";
  // Get the grid formatter client
  const gridFormatterClient = new InputDataGridFormatterClient([], {});
  // This allows to refetch comments when a comment is added or updated
  const [refetchComments, setRefetchComments] = React.useState(false);
  // Fetch all comments for the group
  const {
    data: comments,
    isLoading: isCommentsLoading,
    isError: isCommentsFetchError,
  } = useFetchAllComments(props.groupId, refetchComments);
  // Fetch position attributes for the position type
  const {
    data: positionAttributes,
    isLoading: isPositionAttributesLoading,
    isError: isErrorinPositionAttributes,
  } = useFetchPositionAttributes(props.positionType);
  // If there is an error in fetching comments or position attributes, show an error message through toast
  if (isCommentsFetchError || isErrorinPositionAttributes) {
    if (isCommentsFetchError) {
      toast.error(
        `Error in fetching comments! ${
          !props.groupId && "No group ID found to fetch comments!"
        }`
      );
    }
    if (isErrorinPositionAttributes) {
      toast.error("Error in fetching position attributes!");
    }
  }
  return (
    <Box style={{ width: matches ? "1000px" : "1100px" }}>
      <Box
        sx={
          gridFormatterClient?.getInputGridWrapperStyles(isApSum)
            .containerStyle || {}
        }
      >
        {/* HEADER FOR GRIDS */}
        <Typography
          variant="h6"
          sx={
            gridFormatterClient?.getInputGridWrapperStyles(isApSum)
              .typographyStyle || {}
          }
        >
          {props.heading}
        </Typography>
      </Box>
      {/* LOADING MESSAGE OF COMMENT AND ATTRIBUTES LOADING */}
      {isCommentsLoading || isPositionAttributesLoading ? (
        <Box
          sx={{
            margin: "1rem",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h7" color="primary" marginBottom={1}>
            Loading position attributes and comments for grid cells...
          </Typography>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {/* HGB INPUT DATA GRID */}
          <EditDataGrid
            groupId={props.groupId}
            positionType={props.positionType}
            positionAttributes={positionAttributes}
            onRowUpdate={props.handleUpdate}
            refreshData={props.refreshData}
            borrowerGroupId={props.borrowerGroupId}
            showPositions={props.showPositions}
            comments={comments}
            refetchComments={refetchComments}
            setRefetchComments={setRefetchComments}
            editable={props.editable}
          ></EditDataGrid>
        </Box>
      )}
    </Box>
  );
}

// PROP TYPES
EditDataGridWrapper.propTypes = {
  positionType: PropTypes.string.isRequired,
  refreshData: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  borrowerGroupId: PropTypes.number || null,
  groupId: PropTypes.number || null,
  editable: PropTypes.bool,
};

export default EditDataGridWrapper;
