import React from "react";
import ForcastDataGridWrapper from "./ForcastDataGridWrapper";
import { Box } from "@mui/system";
import {
  useFetchForecastScenarioForecastTimeSlices,
  useFetchPositionAttributes,
} from "../../hooks";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Button, Skeleton, Typography } from "@mui/material";
import { Layout } from "../Layout/Layout";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputDataGridFormatterClient from "../../utils/InputDataGridFormatterClient/InputDataGridFormatterClient";
import { getForeCastGridColumns } from "./ForcastDataGridHelpers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// The main component for the Forecast Detail View page => Shows page with all the forecast grids
const ForecastDetailView = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { forecastScenarioId } = useParams();
  const matches = useMediaQuery("(max-width:1500px)");
  // Fetch the time slices for the forecast scenario id
  const {
    data: timeSlices,
    isLoading: isTimeSlicesLoading,
    isError: isTimeSlicesError,
  } = useFetchForecastScenarioForecastTimeSlices(forecastScenarioId);
  // Fetch all the position attributes
  const {
    data: positionAttributes,
    isLoading: isLoadingPositionAttributes,
    isError: isErrorPositionAttributes,
  } = useFetchPositionAttributes();

  // Create a new instance of the InputDataGridFormatterClient
  const gridFormatterClient = new InputDataGridFormatterClient(
    positionAttributes
  );

  // Get the columns for the base years
  const columnsForBaseYears = getForeCastGridColumns(
    "base",
    gridFormatterClient,
    timeSlices,
    location
  );
  // Get the columns for the liquidity
  const columnsForLiquidity = getForeCastGridColumns(
    "liquidity",
    gridFormatterClient,
    timeSlices,
    location
  );
 // If the time slices or position attributes are loading, show a loading screen
  if (isTimeSlicesLoading || isLoadingPositionAttributes)
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Skeleton
          data-testid={"forecast-grid-loading"}
          variant="rectangular"
          width={"70vw"}
          height={"10vh"}
        />
        <Skeleton
          data-testid={"forecast-grid-loading-1"}
          variant="rectangular"
          width={"70vw"}
          height={"10vh"}
        />
        <Skeleton
          data-testid={"forecast-grid-loading-2"}
          variant="rectangular"
          width={"70vw"}
          height={"10vh"}
        />
        <Skeleton
          data-testid={"forecast-grid-loading-3"}
          variant="rectangular"
          width={"70vw"}
          height={"10vh"}
        />
      </Box>
    );
 // If there is an error, show an error message
  if (isTimeSlicesError || isErrorPositionAttributes)
    return (
      <Typography variant="h6" color="error" textAlign={"center"}>
        {" "}
        ⚠️ Something went wrong!!
      </Typography>
    );

  return (
    <Layout>
      <Box>
        {/* Back button */}
        <Button
          variant="outlined"
          color="primary"
          sx={{ marginLeft: "1rem", marginBottom: "1.5rem" }}
          onClick={() => {
            const currentSearchParams = searchParams.toString(); // Get current query params as string
            const newPath = `/company/${companyId}/forecast`;

            const queryString = currentSearchParams
              ? `?${currentSearchParams}`
              : "";
            navigate(`${newPath}${queryString}`);
          }} // Adjust the route as needed
        >
          <ArrowBackIcon sx={{ marginRight: "0.5rem" }} />
          Back
        </Button>

        {/*  Main container for all grids */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              alignItems: "flex-start",
              gap: matches ? 5 : 10,
            }}
          >
            <ForcastDataGridWrapper
              forecastScenarioId={forecastScenarioId}
              positionType="activa"
              columns={columnsForBaseYears}
              positionAttributes={positionAttributes}
              gridFormatterClient={gridFormatterClient}
            />
            <ForcastDataGridWrapper
              forecastScenarioId={forecastScenarioId}
              positionType="passiva"
              columns={columnsForBaseYears}
              positionAttributes={positionAttributes}
              gridFormatterClient={gridFormatterClient}
            />
          </Box>
          <Box sx={{ margin: "2rem 0rem" }}>
            <ForcastDataGridWrapper
              forecastScenarioId={forecastScenarioId}
              positionType="ap_sum"
              columns={columnsForBaseYears}
              positionAttributes={positionAttributes}
              gridFormatterClient={gridFormatterClient}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              alignItems: "flex-start",
              gap: matches ? 5 : 10,
            }}
          >
            <ForcastDataGridWrapper
              forecastScenarioId={forecastScenarioId}
              positionType="pl"
              columns={columnsForBaseYears}
              positionAttributes={positionAttributes}
              gridFormatterClient={gridFormatterClient}
            />
            <ForcastDataGridWrapper
              forecastScenarioId={forecastScenarioId}
              positionType="other"
              columns={columnsForBaseYears}
              positionAttributes={positionAttributes}
              gridFormatterClient={gridFormatterClient}
            />
          </Box>
          <Box sx={{ margin: "2rem 0rem" }}>
            <ForcastDataGridWrapper
              forecastScenarioId={forecastScenarioId}
              positionType="liquidity"
              columns={columnsForLiquidity}
              positionAttributes={positionAttributes}
              gridFormatterClient={gridFormatterClient}
            />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default ForecastDetailView;
