import { riskStudioColors as colors } from "../../styles/colors";
import { getHeaderNameForMonthYear } from "../EditDataGrid/UtilsForEditDataGrids";

/**
 * This is util function to get grid columns based on columns type.
 * @param {String} columnsType - Columns type e.g: base, liquidity.
 * @param {Object} gridFormatterClient - InputDataGridFormatterClient instance.
 * @param {Array} timeSlices - Time slices data.
 * @param {Object} location - React router location object.
 * @return {Array} Grid columns.
 */
export const getForeCastGridColumns = (
  columnsType,
  gridFormatterClient,
  timeSlices,
  location
) => {
  // Create base year column
  const baseYearColumn = 
    {
      field: "base",
      minWidth: 150,
      flex: 1,
      editable: false,
      headerAlign: "center",
      align: "right",
      cellClassName: gridFormatterClient.generateCellClassNameForBalanceSum,
      renderCell: gridFormatterClient.renderCellForValues,
      renderHeader: (_) => {
        return (
          <strong style={{ color: colors.balanceSumTextColor }}>
            {location.state?.yearMonth
              ? `${getHeaderNameForMonthYear(location.state.yearMonth)} (Base)`
              : "Base"}
          </strong>
        );
      },
    };

  // Create position name column
  const positionNameColumn = {
    field: "name",
    width: 450,
    renderCell: gridFormatterClient.renderCellForPositionName,
    renderHeader: (_) => {
      return (
        <strong style={{ color: colors.balanceSumTextColor }}>
          Position Name
        </strong>
      );
    },
  };

  // Create additional columns based on time slices
  const timeSliceColumns = timeSlices
    ? timeSlices.map((timeSlice) => ({
        field: String(timeSlice.id), // Ensure the field is a string
        headerName: timeSlice.year_month,
        flex: 1,
        headerAlign: "center",
        align: "right",
        editable: true,
        cellClassName: gridFormatterClient.generateCellClassNameForBalanceSum,
        renderCell: gridFormatterClient.renderCellForValues,
        renderHeader: (_) => {
          return (
            <strong style={{ color: colors.balanceSumTextColor }}>
              {getHeaderNameForMonthYear(timeSlice.year_month)}
            </strong>
          );
        },
      }))
    : [];
  // Return columns based on columns type
  if (columnsType === "base") {
    return [positionNameColumn, baseYearColumn, ...timeSliceColumns];
  } else if (columnsType === "liquidity") {
    return [positionNameColumn, ...timeSliceColumns];
  } else {
    return [];
  }
};
/**
 * This is util function to generate sub heading for liquidity grid.
 * @param {String} id - row id
 * @param {String} name - row name
 * @param {String} key - row key
 * @param {Object} modelObj - model object
 * @param {Boolean} subHeading - is sub heading or not
 * @return {Object} position object
 */
const getSubHeadingForLiquidityGrid = (
  id,
  name,
  key,
  modelObj,
  subHeading = true
) => {
  const transformedObj = {
    id,
    name,
    key,
    subHeading,
  };
  for (const prop of Object.keys(modelObj)) {
    if (prop !== "id" && prop !== "name" && prop !== "key") {
      transformedObj[prop] = "";
    }
  }
  return transformedObj;
};

/**
 * This is util function to convert row data for liquidity grid mainly.
 * @param {Array} rowData - row data
 * @param {String} positionType - position type e.g: liquidity
 * @return {Array} converted row data
 */
export const getConvertedRowForForcastGrid = (rowData, positionType) => {
  if (rowData && rowData.length > 0) {
    if (positionType === "liquidity") {
      const model = rowData[0];
      const emptyGap1 = getSubHeadingForLiquidityGrid(
        "gap-liquidity-1-id",
        "EMPTY",
        "gap-liquidity-1",
        model
      );
      const emptyGap2 = getSubHeadingForLiquidityGrid(
        "gap-liquidity-2-id",
        "EMPTY",
        "gap-liquidity-2",
        model
      );
      const emptyGap3 = getSubHeadingForLiquidityGrid(
        "gap-liquidity-3-id",
        "EMPTY",
        "gap-liquidity-3",
        model
      );
      const mittelherkunftTitle = getSubHeadingForLiquidityGrid(
        "mittelherkunft-id",
        "Mittelherkunft (Sources of Liquidity)",
        "mittelherkunft",
        model
      );
      const mittelverwendungTitle = getSubHeadingForLiquidityGrid(
        "mittelverwendung-id",
        "Mittelverwendung (Uses of Liquidity)",
        "mittelverwendung",
        model
      );
      const mittelveranderungTitle = getSubHeadingForLiquidityGrid(
        "mittelveranderung-id",
        "Mittelveränderung (Change of Liquidity)",
        "mittelveranderung",
        model
      );
      const liquiditatsindikatorenTitle = getSubHeadingForLiquidityGrid(
        "liquiditatsindikatoren-id",
        "Liquiditätsindikatoren",
        "liquiditatsindikatoren",
        model
      );

      return [
        ...rowData.slice(0, 3),
        emptyGap1,
        mittelherkunftTitle,
        ...rowData.slice(3, 13),
        emptyGap2,
        mittelverwendungTitle,
        ...rowData.slice(13, 24),
        emptyGap3,
        mittelveranderungTitle,
        ...rowData.slice(24, 29),
        liquiditatsindikatorenTitle,
        ...rowData.slice(29),
      ];
    }
  }
  return rowData;
};

/**
 * This is variable to get extra position attributes for liquidity.
 * @type {Array}
 */

export const getPositionAttributesForLiquidity = () => {
  const commonPositionAttributesForLiquidity = {
    position_type: "liquidity",
    level: 1,
    font_weight: "bold",
  };
  return [
    {
      id: "mittelherkunft",
      name: "Mittelherkunft (Sources of Liquidity)",
      ...commonPositionAttributesForLiquidity,
    },
    {
      id: "mittelverwendung",
      name: "Mittelverwendung (Uses of Liquidity)",
      ...commonPositionAttributesForLiquidity,
    },
    {
      id: "mittelveranderung",
      name: "Mittelveränderung (Change of Liquidity)",
      ...commonPositionAttributesForLiquidity,
    },
    {
      id: "liquiditatsindikatoren",
      name: "Liquiditätsindikatoren",
      ...commonPositionAttributesForLiquidity,
    },
    {
      id: "gap-liquidity-1",
      name: "",
      ...commonPositionAttributesForLiquidity,
    },
    {
      id: "gap-liquidity-2",
      name: "",
      ...commonPositionAttributesForLiquidity,
    },
    {
      id: "gap-liquidity-3",
      name: "",
      ...commonPositionAttributesForLiquidity,
    },
  ];
};
