import {useQuery} from "@tanstack/react-query";
import {
    fetchDDCallTemplates,
    getDDCalls,
    getQaForDdCall,
    fetchDDCallTranscript,
    fetchTranscriptSpeaker
} from "../../api";

export const useFetchDdCalls = (companyId) => {
    return useQuery({queryKey: ['ddCalls', companyId], queryFn: () => getDDCalls(companyId)});
};
export const useFetchQa = (ddCallId) => {
    return useQuery({queryKey: ['qaData', ddCallId], queryFn: () => getQaForDdCall(ddCallId)});
}
export const useFetchTemplates = () => {
    return useQuery({queryKey: ['ddCallTemplates'], queryFn: () => fetchDDCallTemplates()});
}

export const useFetchTranscript = (ddCallId) => {
    return useQuery({queryKey: ['ddCallTranscript', ddCallId], queryFn: () => fetchDDCallTranscript(ddCallId)});
}

export const useFetchTranscriptSpeaker = (ddCallId) => {
    return useQuery({queryKey: ['ddCallTranscriptSpeaker', ddCallId], queryFn: () => fetchTranscriptSpeaker(ddCallId)});
}