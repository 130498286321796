import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import * as api from "../../api";
import { useSnackbar } from "../../context/SnackbarContext";
import { useParams } from "react-router-dom";
import { BsCheck2Square } from "react-icons/bs";
import GroupNameTextField from "./GroupNameTextField";

const CreateSelectionDialog = ({
  onCreate,
  openSelectionDialog,
  setOpenSelectionDialog,
}) => {
  const [open, setOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [timeSliceList, setTimeSliceList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(""); // Add this line
  const openSnackbar = useSnackbar();
  const { companyId } = useParams();
  const [groupName, setGroupName] = useState("");
  const [newGroupIds, setNewGroupIds] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
    // Create the row selection on open
    if (newGroupIds.length > 0) {
      const newSelection = {};
      newGroupIds.forEach((id) => (newSelection[id] = true));
      setRowSelection(newSelection);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setErrorMessage(""); // Reset the error message when closing the dialog
  };

  const handleSelection = (newValue) => {
    setRowSelection(newValue);
  };

  const timeSliceColumns = [
    {
      accessorKey: "id",
      header: "id",
      size: 50,
    },
    {
      accessorKey: "year_month",
      header: "Year Month",
      size: 50,
    },
    {
      accessorKey: "type",
      header: "File Type",
      size: 50,
    },
  ];

  useEffect(() => {
    const fetchTimeSlices = async () => {
      try {
        const response = await api.fetchTimeSlices(companyId);
        setTimeSliceList(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (open) {
      fetchTimeSlices();
      api
        .fetchNewerTimeSlices(companyId)
        .then((res) => setNewGroupIds(res.newer_time_slices));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleCreate = async () => {
    const selectedTimeSlices = Object.keys(rowSelection).map((key) =>
      Number(key)
    );

    const data = { time_slice_ids: selectedTimeSlices };
    if (groupName !== "") {
      data["group_name"] = groupName;
    }

    try {
      const response = await api.postTimeSliceGroup(data);
      onCreate(response);
      handleClose(); // Close the dialog if the request is successful
      setRowSelection({}); //Set the selection to empty
      setGroupName("");
    } catch (error) {
      console.log(error);
      openSnackbar(error?.response?.data?.detail); // Set the error message if the request fails
    }
  };
  // SIDE EFFECT FOR SELECTION DIALOG OPEN BASED ON NEW GROUP IDS
  useEffect(() => {
    if (openSelectionDialog === true) {
      api
        .fetchNewerTimeSlices(companyId)
        .then((res) => {
          setNewGroupIds(res.newer_time_slices);
          return res;
        })
        .then((res) => {
          // Create the row selection
          const newSelection = {};
          res.newer_time_slices.forEach((id) => (newSelection[id] = true));
          setRowSelection(newSelection);
        })
        .then(() => {
          if (setOpenSelectionDialog) {
            setOpen(openSelectionDialog);
            setOpenSelectionDialog(false);
          }
        });
    }

    return () => {
      setOpen(false);
      if (setOpenSelectionDialog) {
        setOpenSelectionDialog(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSelectionDialog]);

  return (
    <Box>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        <BsCheck2Square
          style={{ marginBottom: "3px", marginRight: "5px", fontSize: "1rem" }}
        />
        Create Selection
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ color: "teal", margin: "1rem 0rem 0rem 0.5rem" }}>
          Create Group Selection
        </DialogTitle>
        <GroupNameTextField groupName={groupName} setGroupName={setGroupName} />
        <DialogContent>
          <Box>
            {errorMessage && (
              <Typography color="error">{errorMessage}</Typography>
            )}
          </Box>
          <Box
            sx={{
              borderRadius: "2px",
              boxShadow: "0 0px 0px 5px rgba(75, 167, 155, 0.1)",
              margin: "0rem 0.5rem",
            }}
          >
            <MaterialReactTable
              columns={timeSliceColumns}
              data={timeSliceList}
              enableRowSelection
              onRowSelectionChange={handleSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              getRowId={(originalRow) => originalRow.id}
              muiTableBodyRowProps={({ row }) => ({
                onClick: row.getToggleSelectedHandler(),
                sx: { cursor: "pointer" },
              })}
              initialState={{
                sorting: [
                  {
                    id: "year_month", //sort by age by default on page load
                    desc: true,
                  },
                ],
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            sx={{ margin: "0rem 0.5rem 1rem 0rem" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreate}
            sx={{ margin: "0rem 2rem 1rem 2rem" }}
            disabled={Object.keys(rowSelection).length === 0}
            data-testId="group-selection-create__btn"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

CreateSelectionDialog.propTypes = {
  onCreate: PropTypes.func.isRequired,
  setOpenSelectionDialog: PropTypes.func.isRequired,
  openSelectionDialog: PropTypes.bool.isRequired,
};

export default CreateSelectionDialog;
