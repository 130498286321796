/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as api from "../../api";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid } from "@mui/x-data-grid";
import { useSnackbar } from "../../context/SnackbarContext";
import { useParams } from "react-router-dom";
import { useMutation } from "./HooksForEditDataGrid";
import { useTheme } from "@mui/material/styles";
import { processTimeslicesToColumnHeaders } from "./UtilsForEditDataGrids";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CustomColumnMenuForInputDataGrid } from "./CustomMenuItemForInputDataGrid";
import InputDataGridFormatterClient from "../../utils/InputDataGridFormatterClient/InputDataGridFormatterClient";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { RowCommentDialog } from "./RowCommentDialog";

const EditDataGrid = ({
  positionType,
  positionAttributes,
  groupId,
  onRowUpdate,
  refreshData,
  borrowerGroupId,
  showPositions,
  comments,
  refetchComments,
  setRefetchComments,
  editable,
}) => {
  const [timeSlices, setTimeSlices] = useState([]);
  const [timeSliceResponseData, setTimeSliceResponseData] = useState([]);
  const [positionData, setPositionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const mutateRow = useMutation();
  const openSnackbar = useSnackbar();
  const theme = useTheme();
  const { companyId } = useParams();
  const matches = useMediaQuery("(max-width:1500px)");

  const getPositions = (groupId) => {
    setLoading(true);
    if (groupId === null) {
      // if no group id, get all time slices
      setLoading(false);
    } else {
      return api
        .fetchPositionsFromGroup(groupId, positionType, borrowerGroupId)
        .then((response) => {
          setPositionData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  };

  const processRowUpdate = React.useCallback(
    async (updatedRow, originalRow, positionAttributes, groupId) => {
      try {
        // Make the HTTP request to save in the backend
        const response = await mutateRow(
          updatedRow,
          originalRow,
          positionAttributes
        );
        // This is no longer needed, as onRowUpdate updates refresh data, and this calls get positions again
        // getPositions(groupId);
        onRowUpdate(groupId); // Callback to the outer components to update KPIS etc
        return response;
      } catch (error) {
        console.error("Error in processRowUpdate:", error);
        throw error; // If you want the error to propagate
      }
    },
    [mutateRow]
  );

  const handleProcessRowUpdateError = () => {
    openSnackbar(
      "The value is not valid. It can only contain numbers, -, + and ."
    );
  };

  // SIDE EFFECT:
  useEffect(() => {
    if (positionAttributes?.length > 0) {
      // If positionAttributes data is available, fetch timeSlices data
      if (groupId === null) {
        api
          .fetchTimeSlices(companyId)
          .then((response) => {
            processTimeslicesToColumnHeaders(
              response.data,
              setTimeSlices,
              showPositions,
              gridFormatterClient
            );
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        api
          .fetchTimeSlicesFromGroup(groupId)
          .then((response) => {
            setTimeSliceResponseData(response.data);
            processTimeslicesToColumnHeaders(
              response.data,
              setTimeSlices,
              showPositions,
              gridFormatterClient
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, [positionAttributes, groupId, companyId]);

  // SIDE EFFECT: only get Postions (rows) once TimeSlices (Columns) are loaded
  useEffect(() => {
    if (timeSlices.length > 0) {
      getPositions(groupId);
    }
  }, [timeSlices, groupId]);

  // SIDE EFFECTS FOR BILANZSUMMCHECK
  useEffect(() => {
    getPositions(groupId);
  }, [refreshData]);

  // //-----------------For Comment----------------START---\\
  const [contextMenu, setContextMenu] = React.useState(null);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [openCommentDialog, setOpenCommentDialog] = React.useState(false);
  // Handle Comment Dialog Close
  const handleCommentDialogClose = () => {
    setOpenCommentDialog(false);
  };

  // Handle Context Menu on right click over grid
  const handleContextMenu = (event) => {
    event.preventDefault();
    const rowData = positionData.find(
      (p) => p.id === Number(event.currentTarget.getAttribute("data-id"))
    );
    setSelectedRowData(rowData);
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };
  // Close Context Menu
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };
  // Handle Comment Menu Click inside Context Menu
  const handleCommentMenuClick = () => {
    setOpenCommentDialog(true);
    handleCloseContextMenu();
  };
  // Handle Update Row when Comment dialog is closed
  const handleUpdateRow = (payloadForUpdateRow) => {
    // Instantiate the formatter client with new comments
    gridFormatterClient = new InputDataGridFormatterClient(
      positionAttributes,
      comments
    );
    // Update the row with the new comment in UI only by updating the positionData
    setPositionData((prevData) => {
      return prevData.map((p) => {
        if (p.key === payloadForUpdateRow.key) {
          return { ...p, comment: payloadForUpdateRow };
        }
        return p;
      });
    });
  };
  //  \\-----------------For Comment---------------END----//

  // GRID FORMATTER CLIENT
  let gridFormatterClient = new InputDataGridFormatterClient(
    positionAttributes,
    comments
  );
  // RENDER DATA GRID
  const renderDataGrid = () => {
    return (
      <DataGrid
        columns={timeSlices}
        rows={positionData}
        isCellEditable={gridFormatterClient.getIsCellEditable}
        getRowHeight={() => "auto"}
        processRowUpdate={(updatedRow, originalRow) =>
          processRowUpdate(
            updatedRow,
            originalRow,
            { positionAttributes },
            groupId
          )
        }
        density="compact"
        onProcessRowUpdateError={handleProcessRowUpdateError}
        hideFooter={true}
        slots={{
          // Custom Toolbar
          toolbar: gridFormatterClient.getGridCustomToolbar || null,
          // Custom Menu for Get Documents
          columnMenu: CustomColumnMenuForInputDataGrid,
        }}
        slotProps={{
          row: {
            onContextMenu: handleContextMenu,
            style: { cursor: "context-menu" },
          },
        }}
        disableRowSelectionOnClick
        dis
        sx={gridFormatterClient.getDataGridPrimaryStyles()}
      />
    );
  };
  return (
    <Box
      sx={gridFormatterClient.getDataGridPrimaryContainerStyles(
        matches,
        theme,
        positionData.length
      )}
    >
      {loading ? <LinearProgress color="success" /> : <div />}
      {/* RENDER MAIN DATA GRID */}
      {positionAttributes?.length > 0 &&
        timeSlices.length > 0 &&
        renderDataGrid()}
      {/* CONTEXT MENU */}
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        slotProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleCloseContextMenu();
            },
          },
        }}
      >
        <MenuItem onClick={handleCommentMenuClick}>➕ Comment</MenuItem>
      </Menu>
      {/* COMMENT DIALOG */}
      <RowCommentDialog
        open={openCommentDialog}
        onClose={handleCommentDialogClose}
        selectedValue={selectedRowData}
        timeSlices={timeSliceResponseData}
        comments={comments}
        refetchComments={refetchComments}
        setRefetchComments={setRefetchComments}
        handleUpdateRow={handleUpdateRow}
      />
    </Box>
  );
};

// PROP TYPES
EditDataGrid.propTypes = {
  positionType: PropTypes.string.isRequired,
  positionAttributes: PropTypes.array.isRequired,
  groupId: PropTypes.string || null,
  onRowUpdate: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired,
  borrowerGroupId: PropTypes.string || null,
  showPositions: PropTypes.bool,
  comments: PropTypes.object,
  refetchComments: PropTypes.bool,
  setRefetchComments: PropTypes.func,
  editable: PropTypes.bool,
};

export default EditDataGrid;
