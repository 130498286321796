/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { Typography, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import * as api from "../../api";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { riskStudioColors as colors } from "../../styles/colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import CrefoDashboard from "./CrefoDashboard";
import Skeleton from "@mui/material/Skeleton";
import { toast } from "sonner";

// THIS IS THE MAIN CREFO COMPONENT
const CrefoComponent = ({ refreshData }) => {
  const [data, setData] = useState([]);
  const { companyId } = useParams();
  const matches = useMediaQuery("(max-width:1920px)");
  const [companyData, setCompanyData] = useState(null);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [refreshCrefoComponent, setRefreshCrefoComponent] = useState(false);

  // SIDE EFFECT: This fetches the data for crefo score and drd index
  useEffect(() => {
    const fetchData = async () => {
      const result = await api.fetchCrefoData(companyId);
      setData(result.data);
    };
    fetchData();
  }, [refreshData]);

  // SIDE EFFECT: This fetches the data for crefo dashboard links
  useEffect(() => {
    setCompanyLoading(true);
    // Fetching company data for crefo links
    api
      .fetchCompany(companyId) // e.g 315408
      .then((data) => {
        setCompanyData(data);
        setCompanyLoading(false);
      })
      .catch((err) => {
        setCompanyLoading(false);
        toast.error("Error fetching company data for crefo links!");
      });
    return () => setRefreshCrefoComponent(false);
  }, [companyId, refreshCrefoComponent]);

  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={3}
      sx={{
        margin: matches ? "2rem 4rem" : "2rem 0rem 2rem 12rem",
      }}
    >
      <Typography variant="h7">
        Crefo Score:{" "}
        <Typography
          variant="h7"
          sx={{ color: colors.gridWrapperPrimaryColor, fontWeight: "bold" }}
        >
          {data?.solvency_index}
        </Typography>
      </Typography>
      <Typography variant="h7">
        DRD Index:{" "}
        <Typography
          variant="h7"
          sx={{ color: colors.gridWrapperPrimaryColor, fontWeight: "bold" }}
        >
          {data?.payment_behavior_index}
        </Typography>
      </Typography>
      {/* CREFO LINKS - DASHBOARD */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h7" sx={{ marginRight: "15px" }}>
          Crefo Dashboard:
        </Typography>
        {companyLoading ? (
          <Skeleton variant="rectangular" width={150} height={30} />
        ) : (
          <CrefoDashboard
            companyData={companyData}
            setRefreshCrefoComponent={setRefreshCrefoComponent}
          />
        )}
      </Box>
    </Stack>
  );
};

CrefoComponent.propTypes = {
  refreshData: PropTypes.bool.isRequired,
};
export default CrefoComponent;
