import { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import ImageResize from "quill-image-resize-module-react";

import "./styles.css";
import { Box } from "@mui/material";
import { getModules } from "./quillUtils";

Quill.register("modules/imageResize", ImageResize);

/*
 * Simple editor component that takes placeholder text as a prop
 */
class QuillWithResize extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(html) {
    this.props.setValue(html);
  }

  render() {
    return (
      <Box
        sx={{
          border: this.props.theme === "bubble" ? "1px solid lightgray" : "",
        }}
      >
        <ReactQuill
          theme={this.props.theme}
          onChange={this.handleChange}
          value={this.props.value}
          modules={getModules(this.props.toolbar, Quill.import("parchment"))}
          bounds={`#root`}
          placeholder={this.props.placeholder}
          onBlur={this.props.updateDataPoint}
        />
      </Box>
    );
  }
}

export default QuillWithResize;
