import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Step,
  StepLabel,
  Stepper,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import MonthYearPicker from "./CreateDialogHelpers/MonthYearPicker";
import SelectFiles from "./CreateDialogHelpers/SelectFiles";
import { getSteps } from "./CreateDialogHelpers/options";
import OptionsRadioGroup from "./CreateDialogHelpers/OptionsRadioGroup";
import * as api from "../../api";
import { useSnackbar } from "../../context/SnackbarContext";
import { useParams } from "react-router-dom";
import { IoCreate } from "react-icons/io5";
import dayjs from "dayjs";

function CreateDialog({
  onCreate,
  setOpenSelectionDialog,
}) {
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [steps, setSteps] = useState([]);
  const { companyId } = useParams();
  const openSnackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  // specific for the selection:
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [susa, setSusa] = useState(false);
  const [comlineReport, setComlineReport] = useState(false);
  const [manualReportCSV, setManualReportCSV] = useState(false);

  // COMPONENT SIDE EFFECT
  useEffect(() => {
    setSteps(getSteps(selectedOption));
    setActiveStep(0); // Reset to the first step when the option changes
  }, [selectedOption]);

  useEffect(() => {}, [selectedDate]);

  // HANDLERS
  const handleClickOpen = () => {
    setOpen(true);
    setSusa(false);
  };
  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
    setSusa(false);
    setComlineReport(false);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep - 1 === 0) {
        setComlineReport(false);
        setManualReportCSV(false);
        setSusa(false);
      }
      return prevActiveStep - 1;
    });
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  // ON CHANGES OF OPTIONS
  const onDateChangeManual = (newValue) => {
    setSelectedDate(newValue);
  };

  const onSelectionManualReport = (newValue) => {
    setSelectedFiles(newValue);
  };

  const onSelectionSusa = (newValue, selectedDates) => {
    if (selectedDates.length === 1) {
      setSelectedDate(
        dayjs(
          `${selectedDates[0].reporting_year}-${selectedDates[0].reporting_month}-01`
        )
      );
    }
    setSelectedFiles(newValue);
    setSusa(true);
  };

  const onSelectionComlineReport = (newValue, selectedDates) => {
    if (selectedDates.length === 1) {
      setSelectedDate(
        dayjs(
          `${selectedDates[0].reporting_year}-${selectedDates[0].reporting_month}-01`
        )
      );
    }
    setSelectedFiles(newValue);
    setComlineReport(true);
  };
  const onSelectionCSVReport = (newValue) => {
    setSelectedFiles(newValue);
    setManualReportCSV(true);
  };

  const onAPISuccess = (response) => {
    // Call the newer time slice ids for group selection
    const responseNewer = api.fetchNewerTimeSlices(companyId);
    // Open the dialog if the newer time slices ids are there
    responseNewer.then(
      (res) => res.newer_time_slices.length > 0 && setOpenSelectionDialog(true)
    );
    // call the callback function
    onCreate();
    setLoading(false);
    handleClose();

    const successMsg = response.data.detail;
    const warningMsg = response.data?.warning;
    if (warningMsg) {
      const messages = [
        {
          message: successMsg,
          severity: "success",
        },
        { message: warningMsg, severity: "warning" },
      ];

      openSnackbar(messages);
    } else {
      openSnackbar(successMsg, "success");
    }
    setManualReportCSV(false);
    setComlineReport(false);
    setSusa(false);
  };
  const onAPIFail = (msg) => {
    setLoading(false);
    openSnackbar(msg);
  };
  // HANDLERS FOR CREATING FILES
  const createManualReportCSV = (files) => {
    const dataList = files.map((file) => ({
      document_id: file.id,
      company_id: companyId,
    }));

    const apiFuncForManualReport = async (data) => {
      const res = await api.postTimeSliceCSV(data);
      return res;
    };
    const executeSequently = async () => {
      await apiFuncForManualReport(dataList[0])
        .then((res) => {
          onAPISuccess(res);
        })
        .catch((error) => {
          onAPIFail(error?.response?.data?.detail);
        });
    };
    executeSequently();
  };
  // Handle form submission
  const handleCreate = () => {
    // Perform the create action and call onCreate callback
    setLoading(true);
    const transformedFiles = Object.keys(selectedFiles).map((key) => ({
      id: Number(key),
    }));

    if (manualReportCSV) {
      createManualReportCSV(transformedFiles);
      return;
    }
    let data = {
      year_month: selectedDate.format("YYYY-MM"),
      documents: transformedFiles,
      company_id: companyId,
    };
    if (susa === true) {
      data["extracted_document_id"] = Object.keys(selectedFiles)[0];
    }

    if (comlineReport === true) {
      data["comline_document_id"] = Object.keys(selectedFiles)[0];
    }
    // Note the addition of (data) to call the function and use its returned promise
    if (manualReportCSV === false) {
      api
        .postTimeSlice(data)
        .then((response) => {
          onAPISuccess(response);
        })
        .catch((error) => {
          onAPIFail(error?.response?.data?.detail);
        });
    }
  };

  return (
    <div>
      <Button
        data-testid="import-btn"
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        size="small"
      >
        <IoCreate
          style={{ marginBottom: "3px", marginRight: "5px", fontSize: "1rem" }}
        />{" "}
        Create
      </Button>
      <Dialog data-testid="import-dialog" open={open} onClose={handleClose}>
        <DialogTitle>Create</DialogTitle>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label.id}>
              <StepLabel>{label.name}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <DialogContent>
          {activeStep === 0 && (
            <OptionsRadioGroup
              selectedOption={selectedOption}
              onOptionChange={handleRadioChange}
            />
          )}
          {steps[activeStep]?.id === "1_select_date" && (
            <MonthYearPicker
              onDateChange={onDateChangeManual}
              initDate={selectedDate}
            />
          )}
          {steps[activeStep]?.id === "1_select_file" && (
            <SelectFiles
              onSelection={onSelectionComlineReport}
              selectMultiple={false}
              queryType={{ companyId, fileType: 1 }}
            />
          )}
          {steps[activeStep]?.id === "2_select_files" && (
            <SelectFiles
              onSelection={onSelectionCSVReport}
              selectMultiple={false}
              queryType={{ companyId, fileType: 2 }}
            />
          )}
          {steps[activeStep]?.id === "3_select_date" && (
            <MonthYearPicker
              onDateChange={onDateChangeManual}
              initDate={selectedDate}
            />
          )}
          {steps[activeStep]?.id === "3_select_file" && (
            <SelectFiles
              onSelection={onSelectionSusa}
              selectMultiple={false}
              queryType={{ companyId, fileType: 3 }}
            />
          )}
          {steps[activeStep]?.id === "4_select_date" && (
            <MonthYearPicker
              onDateChange={onDateChangeManual}
              initDate={null}
            />
          )}
          {steps[activeStep]?.id === "4_select_files" && (
            <SelectFiles
              onSelection={onSelectionManualReport}
              queryType={{ companyId, fileType: 4 }}
            />
          )}

          {/* ... render other steps components based on activeStep ... */}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            data-testid="import-back"
          >
            Back
          </Button>
          <Button
            data-testid="import-next"
            variant="contained"
            color="primary"
            onClick={
              activeStep === steps.length - 1 ? handleCreate : handleNext
            }
            disabled={
              loading ||
              (steps[activeStep]?.id === "4_select_date" &&
                selectedDate === null) ||
              (activeStep === steps.length - 1 &&
                Object.keys(selectedFiles).length === 0) ||
              (activeStep === 0 && selectedOption === "")
            }
            startIcon={
              loading ? <CircularProgress size={24} color="inherit" /> : null
            } // Add this line
          >
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
CreateDialog.propTypes = {
  onCreate: PropTypes.func.isRequired,
  setOpenSelectionDialog: PropTypes.func.isRequired,
};

export default CreateDialog;
