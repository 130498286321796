import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from "@mui/material";
import SelectFilesForDocuments from "./SelectFilesForDocuments";
import {
  getCombinedDocumentsData,
  processDataForSelectFiles,
} from "./UtilsForEditDataGrids";
import { updateTimeSliceDocuments } from "../../api";
import { useSnackbar } from "../../context/SnackbarContext";

function SelectDocumentsDialog({
  openDialog,
  setOpenDialog,
  alreadylinkedDocuments,
  timeSliceId,
  allDocuments,
}) {
  const [metaData, setMetaData] = React.useState(null);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const openSnackbar = useSnackbar();

  React.useEffect(() => {
    if (alreadylinkedDocuments && allDocuments) {
      // This provides the result of unique documents
      const combinedData = getCombinedDocumentsData(
        alreadylinkedDocuments,
        allDocuments
      );
      // Returns the data in the format required for the react table in the dialog
      const transformedData = processDataForSelectFiles(combinedData);
      setMetaData(transformedData);
    }
  }, [allDocuments, alreadylinkedDocuments]);

  // Handles the update of the documents
  const handleUpdate = () => {
    updateTimeSliceDocuments(timeSliceId, selectedFiles)
      .then((res) => {
        if (res.status === 200) {
          openSnackbar("Documents Updated Successfully!", "success");
          setOpenDialog(false);
        }
      })
      .catch((errMsg) => {
        // Here err is the detail message of the error coming from the backend
        openSnackbar(errMsg, "error");
        setOpenDialog(false);
      });
  };

  return (
    <Box>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Documents</DialogTitle>
        <DialogContent>
          <SelectFilesForDocuments
            columns={metaData?.columnsForTable}
            rows={metaData?.rowForTable}
            setSelectedFiles={setSelectedFiles}
            allreadySelectedFiles={alreadylinkedDocuments}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleUpdate}>
            Update
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenDialog(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

// Prop types for SelectDocumentsDialog component
SelectDocumentsDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
  alreadylinkedDocuments: PropTypes.array.isRequired,
  timeSliceId: PropTypes.string.isRequired,
  allDocuments: PropTypes.array.isRequired,
};

export default SelectDocumentsDialog;
