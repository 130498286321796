import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Avatar, Typography, Box, Grid } from "@mui/material";
import { fetchRatingsFromGroup } from "../../api";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { riskStudioColors as colors } from "../../styles/colors";
import useMediaQuery from "@mui/material/useMediaQuery";

const RatingCard = ({ title, rating, score, color, pricing = null }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "1rem",
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
      borderRadius: "0.5rem",
    }}
  >
    <Typography
      variant="h6"
      sx={{
        display: "inline",
        marginRight: "3rem",
        paddingRight: "1rem",
        borderRight: `3px solid ${colors.gridBorderColor}`,
      }}
    >
      {`${title}  `}
    </Typography>
    <Stack
      direction="row"
      spacing={3}
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Avatar
          sx={{
            backgroundColor:
              color === null || color === undefined ? undefined : color,
            height: 70,
            width: 70,
          }}
        >
          {score === null || color === null ? "?" : rating}
          {/* {score > 7 ? "CCC/C" : "?"} */}
        </Avatar>

        <Typography sx={{ margin: "0.5rem 0rem" }}>
          Score:{" "}
          <span
            style={{ color: "teal", fontWeight: "bold", letterSpacing: "1px" }}
            data-testid="score-number"
          >
            {score !== undefined ? score.toFixed(2) : "N/A"}
          </span>
        </Typography>
      </Box>
      {pricing && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ margin: "0.5rem 0rem" }}>
            Zins:{" "}
            <span
              style={{
                color: "teal",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
            >
              {`${Number(pricing?.interest_coupon?.lower_boundary).toFixed(
                2
              )}% - ${Number(pricing?.interest_coupon?.upper_boundary).toFixed(
                2
              )}%`}
            </span>
          </Typography>
          <Typography sx={{ margin: "0.5rem 0rem" }}>
            Gebühr:{" "}
            <span
              style={{
                color: "teal",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
            >
              {`${Number(
                pricing?.creditshelf_fee?.origination_fee?.lower_boundary
              ).toFixed(2)}% - ${Number(
                pricing?.creditshelf_fee?.origination_fee?.upper_boundary
              ).toFixed(2)}%`}
            </span>
          </Typography>
        </Box>
      )}
    </Stack>
  </Box>
);

const RatingBadgeComponent = ({ groupId, refreshData, borrowerGroupId }) => {
  const [data, setData] = useState({
    kpi: { actual: [], proforma: [] },
    scores: [],
  });

  const matches = useMediaQuery("(max-width:1500px)");

  const fetchData = async (groupId) => {
    const result = await fetchRatingsFromGroup(groupId, borrowerGroupId);
    setData(result.data);
  };

  useEffect(() => {
    // Define a function to fetch data from the database

    if (groupId !== null) {
      fetchData(groupId);
    } else {
      setData({
        kpi: { actual: [], proforma: [] },
        scores: [],
      });
    }
    // Fetch data whenever `doUpdate` changes
  }, [groupId, refreshData]);

  const getRatingColor = (rating) => {
    // If rating is null or undefined, return default color
    if (rating === null || rating === undefined) {
      return { backgroundColor: "#9e9e9e", color: "#000000" }; // Grey for unrecognized ratings
    }

    let cleanedRating = rating.toLowerCase().replace(/[+-]/g, "");

    switch (cleanedRating) {
      case "aaa":
        return { backgroundColor: "#4caf50", color: "#ffffff" }; // Green
      case "aa":
        return { backgroundColor: "#8bc34a", color: "#000000" }; // Light Green
      case "a":
        return { backgroundColor: "#cddc39", color: "#000000" }; // Lime
      case "bbb":
        return { backgroundColor: "#f0e68c", color: "#000000" }; // Khaki (instead of Yellow)
      case "bb":
        return { backgroundColor: "#ffc107", color: "#000000" }; // Amber
      case "b":
        return { backgroundColor: "#ff9800", color: "#000000" }; // Orange
      case "c":
        return { backgroundColor: "#f44336", color: "#ffffff" }; // Red
      default:
        return { backgroundColor: "#9e9e9e", color: "#000000" }; // Grey for unrecognized ratings
    }
  };

  return (
    <Box
      sx={{ margin: "2rem auto", justifyContent: "center", display: "flex" }}
    >
      <Grid container spacing={4} sx={{ width: matches ? "90vw" : "80vw" }}>
        <Grid item xs={6}>
          <RatingCard
            title="Proforma"
            rating={data?.rating?.proforma?.rating}
            score={data?.rating?.proforma?.score}
            color={getRatingColor(data?.rating?.proforma?.rating)}
            pricing={data.pricing ? data.pricing.proforma : null}
          />
        </Grid>
        <Grid item xs={6}>
          <RatingCard
            title="Analytisch"
            rating={data?.rating?.actual?.rating}
            score={data?.rating?.actual?.score}
            color={getRatingColor(data?.rating?.actual?.rating)}
            pricing={data.pricing ? data.pricing.actual : null}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

RatingBadgeComponent.propTypes = {
  groupId: PropTypes.string,
  refreshData: PropTypes.bool,
  borrowerGroupId: PropTypes.number,
};

RatingCard.propTypes = {
  title: PropTypes.string,
  rating: PropTypes.string,
  score: PropTypes.number,
  color: PropTypes.object,
  pricing: PropTypes.object,
};

export default RatingBadgeComponent;
