import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  Switch,
} from "@mui/material";

import EditQaComponent from "../EditQaComponent";
import AddQaComponent from "../AddQaComponent";
import PropTypes from "prop-types";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { patchQuestionAnswer } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const QuestionView = ({ companyId, viewMode, qaData, selectedDdCallId }) => {
  const queryClient = useQueryClient();
  const agentAnswerExists = qaData.some(
    (qa) => qa.agent_answer !== null && qa.agent_answer !== ""
  );

  const [checked, setChecked] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showQuestions, setShowQuestions] = useState(false);
  const [category, setCategory] = useState("");

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleShowQuestionsChange = (event) => {
    setShowQuestions(event.target.checked);
  };

  const batchMutation = useMutation({
    mutationFn: patchQuestionAnswer,
    onSuccess: () => {
      // Handle batch mutation success here, e.g., refetching data
      queryClient.invalidateQueries({ queryKey: ["qaData", selectedDdCallId] });
    },
    // Handle other mutation callbacks if needed
  });

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleBatchArrowClick = () => {
    handleCloseDialog();
    qaData.forEach((qa) => {
      const shouldShowAnswer = checked && !showQuestions;
      const answerToShow = shouldShowAnswer ? qa.agent_answer : "";

      batchMutation.mutate({
        qaId: qa.id,
        data: {
          question: qa.question,
          answer: answerToShow,
          agent_answer: qa.agent_answer,
          category,
        },
      });
    });
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const groupedQaData = {};
  qaData.forEach((qa) => {
    const category = qa.category || "Keine Kategorie";
    if (!groupedQaData[category]) {
      groupedQaData[category] = [];
    }
    groupedQaData[category].push(qa);
    console.log("group data", groupedQaData);
  });

  return (
    ["questions", "both"].includes(viewMode) && (
      <Box
        sx={{
          width: viewMode === "both" ? "50%" : "100%",
          margin: "4px",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "4px" }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  disabled={!agentAnswerExists}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Zeige AI Antworten"
              data-testid="ai-answers-switch"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showQuestions}
                  onChange={handleShowQuestionsChange}
                  inputProps={{ "aria-label": "show-questions-switch" }}
                />
              }
              label="Nur Fragen Anzeigen"
            />
          </FormGroup>
          {checked && (
            <>
              <IconButton
                onClick={handleOpenDialog}
                color="primary"
                data-testid="QuestionView-IconButton"
              >
                <KeyboardDoubleArrowLeftIcon />
              </IconButton>

              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                data-testid="QuestionView-Dialog"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Bestätigung"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Wollen Sie wirklich alle Antworten mit denen der KI
                    überschreiben?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleCloseDialog}
                    color="primary"
                    data-testid="QuestionView-Dialog-Button-Close"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleBatchArrowClick}
                    color="primary"
                    variant="contained"
                    autoFocus
                    data-testid="QuestionView-Dialog-Button-Confirm"
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </Box>

        {Object.keys(groupedQaData).map((category, index) => (
          <div key={index} style={{ width: "100%", padding: "10px 10px" }}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="index"
                id="index"
              >
                <Input
                  label="Category"
                  value={category}
                  onChange={(e) => {
                    handleCategoryChange(e);
                  }}
                  margin="normal"
                  fullWidth
                  disableUnderline={true}
                />
              </AccordionSummary>
              <AccordionDetails>
                {groupedQaData[category].map((qa) => (
                  <EditQaComponent
                    key={qa.id}
                    qa={qa}
                    companyId={companyId}
                    showAgentAnswer={checked}
                    showQuestionsOnly={showQuestions}
                    onSuccessUpdate={() => {}}
                    ddCallId={selectedDdCallId}
                    style={{
                      width: "100%",
                      border: "1px grey",
                      borderRadius: "5px",
                    }}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
        ))}

        {selectedDdCallId && (
          <>
            <Divider sx={{ my: 2 }} />
            <AddQaComponent ddCallId={selectedDdCallId} />
          </>
        )}
      </Box>
    )
  );
};

export default QuestionView;

QuestionView.propTypes = {
  companyId: PropTypes.string.isRequired,
  viewMode: PropTypes.string.isRequired,
  qaData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
      category: PropTypes.string,
    })
  ).isRequired,
  selectedDdCallId: PropTypes.string.isRequired,
};
