import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { SelectBaseTimeSlice } from "./SelectBaseTimeSlice";
import Divider from "@mui/material/Divider";
import ForecastScenarioOverview from "./ForecastScenarioOverview";
import {useFetchTimeSlices} from "../../hooks";
import LiabilitiesView from "./Liabilities/LiabilitiesView";
import {Box} from "@mui/system";

const ForecastComponent = () => {
  const { companyId } = useParams();
  const { data: timeSlices, isLoading: timeSlicesLoading } =
    useFetchTimeSlices(companyId);
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultTimeSliceId = timeSlices?.data?.[0]?.id || "";
  const initialTimeSliceId =
    searchParams.get("base_time_slice_id") || defaultTimeSliceId;
  const [selectedTimeSlice, setSelectedTimeSlice] =
    useState(initialTimeSliceId);

  const TimeSliceObject = timeSlices?.data.find(
    (timeSlice) => timeSlice.id === parseInt(selectedTimeSlice)
  );

  useEffect(() => {
    // Set the initial state based on the URL or the first item in the data
    if (!searchParams.get("base_time_slice_id") && defaultTimeSliceId) {
      setSelectedTimeSlice(defaultTimeSliceId);
    }
  }, [defaultTimeSliceId, searchParams]);

  const handleTimeSliceSelect = (event) => {
    const newTimeSliceId = event.target.value;
    setSelectedTimeSlice(newTimeSliceId);

    // Update the URL search params
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("base_time_slice_id", newTimeSliceId);
    setSearchParams(updatedSearchParams);
  };

  if (timeSlicesLoading) {
    return <div>Loading...</div>;
  }


    return (
        <Box sx={{paddingX:"2rem"}}>
            <SelectBaseTimeSlice
                timeSlices={timeSlices?.data}
                selectedTimeSlice={selectedTimeSlice}
                onTimeSliceSelect={handleTimeSliceSelect}
            />
            <Divider sx={{ my: 2 }} />
            <Box sx={{paddingY:"2rem"}}>
                <LiabilitiesView companyId={companyId}></LiabilitiesView>
            </Box>
            <Box>
                <ForecastScenarioOverview companyId={companyId} baseTimeSliceId={selectedTimeSlice} yearMonth={TimeSliceObject?.year_month} />
            </Box>

        </Box>
    );
};

export default ForecastComponent;

