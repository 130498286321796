/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import { riskStudioColors as colors } from "../../styles/colors";
import { crefoAPI } from "../../crefoServiceAPI.js";
import { toast } from "sonner";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { CrefoDataFillerUtils } from "./CrefoDataFillerUtils/CrefoDataFillerUtils.jsx";
import CancelIcon from "@mui/icons-material/Cancel";

export const CrefoReportDataFiller = ({
  companyId,
  companyType,
  companyData,
  reportData,
  onBackClick,
}) => {
  // STATE VARIABLES =================>
  const [newData, setNewData] = React.useState(companyData);
  const [erroredFields, setErroredFields] = React.useState({});
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [gicsCode, setGicsCode] = React.useState("");
  const [gicsCodeName, setGicsCodeName] = React.useState("");
  // SIDE EFFECTS =================>
  // Set the GICS code name when the GICS code changes
  React.useEffect(() => {
    reportData?.gics &&
      reportData.gics.length > 0 &&
      setGicsCodeName(() => {
        const data = reportData.gics.find(
          (gics) => gics.code_level_three === gicsCode
        );
        return data ? data.name_level_three_de : "";
      });
  }, [gicsCode, reportData.gics]);
  // RENDER FUNCTIONS =================>
  // Function to render the Table cell
  const renderTableCell = (cspKey, crefoReportValue) => {
    const isNaceCode = cspKey === "nace_code_level_three";
    const isGicsCode = cspKey === "gics_code_level_three";
    if (isGicsCode && crefoReportValue.length > 1) {
      return (
        <Box width={"350px"} sx={{ display: "flex", alignItems: "center" }}>
          <FormControl sx={{ width: "40%" }}>
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                "&.MuiFormLabel-root": {
                  top: "-7px",
                },
              }}
            >
              Choose gics
            </InputLabel>
            <Select
              size="small"
              labelId="select-gics-code-level-three-label"
              id="select-gics-code-level-three"
              value={gicsCode}
              label="GICS"
              onChange={handleGicsCodeChange}
            >
              {crefoReportValue.map((gics, i) => {
                return (
                  <MenuItem
                    value={gics.code_level_three}
                    key={`gics_menu_item-${i + 1}`}
                  >
                    {gics.code_level_three}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <IconButton
            sx={{ margin: "0px 10px" }}
            color="primary"
            size="small"
            onClick={() => {
              feedCspValue(cspKey, gicsCode);
            }}
          >
            <ArrowForwardIcon />
          </IconButton>
          {CrefoDataFillerUtils.getLevelThreeName(gicsCodeName)}
        </Box>
      );
    }
    return (
      <Box>
        <Button
          sx={{ marginRight: "10px" }}
          endIcon={<ArrowForwardIcon />}
          onClick={() => {
            feedCspValue(
              cspKey,
              isGicsCode
                ? crefoReportValue[0].code_level_three
                : crefoReportValue
            );
          }}
          disabled={isGicsCode && crefoReportValue.length === 0}
        >
          {CrefoDataFillerUtils.getButtonTextValueForTableCell(
            isGicsCode,
            crefoReportValue
          )}
        </Button>
        {isNaceCode &&
          CrefoDataFillerUtils.getLevelThreeName(
            reportData?.nace_code_level_three_name
          )}
        {isGicsCode && crefoReportValue.length === 1 && 
          CrefoDataFillerUtils.getLevelThreeName(
            crefoReportValue[0].name_level_three_de
          )}
      </Box>
    );
  };
  // Function to render the Table Row
  const infoDiffRow = (cspKey, crefoReportValue) => {
    const isGicsCode = cspKey === "gics_code_level_three";
    const fieldName = CrefoDataFillerUtils.getFieldNameWithKey(cspKey);
    let cspValue = companyData[cspKey];
    const newValue = newData[cspKey];
    const isValueEqual = CrefoDataFillerUtils.getIsValueEqual(
      cspValue,
      crefoReportValue,
      gicsCode
    );
    const hasCspBeenSelected = cspValue === newValue;
    const hasCrefoBeenSelected = CrefoDataFillerUtils.getHasCrefoBeenSelected(
      cspValue,
      newValue,
      crefoReportValue,
      gicsCode
    );
    const fieldError = erroredFields[cspKey];
    return (
      <TableRow sx={{ height: isGicsCode ? "70px" : "55px" }}>
        <TableCell component="th" scope="row">
          <Typography color={fieldError ? "error" : "none"}>
            {fieldName}
          </Typography>
          {fieldError && <Typography color="error">{fieldError[0]}</Typography>}
        </TableCell>
        <TableCell sx={{ opacity: isValueEqual ? 0.3 : 1 }}>
          {!isValueEqual && hasCspBeenSelected && crefoReportValue ? (
            renderTableCell(cspKey, crefoReportValue)
          ) : (
            <Typography>
              {CrefoDataFillerUtils.getTextValueAfterSelect(
                isGicsCode,
                crefoReportValue,
                gicsCode
              )}
            </Typography>
          )}
        </TableCell>
        <TableCell sx={{ fontWeight: "bold", fontSize: "14px" }}>
          {hasCrefoBeenSelected ? (
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography>{newValue}</Typography>
              <Typography
                sx={{
                  textDecoration: "line-through",
                  fontSize: "12px",
                  opacity: 0.3,
                  cursor: "pointer",
                  alignItems: "center",
                  display: "flex",
                }}
                onClick={() => {
                  feedCspValue(cspKey, cspValue);
                }}
              >
                {cspValue}
                <ClearIcon />
              </Typography>
            </Box>
          ) : (
            <Typography>{newValue}</Typography>
          )}
        </TableCell>
      </TableRow>
    );
  };
  // Function to render the Back and Save Button
  const renderBackSaveButton = (showBackButton, showSaveButton) => {
    let justifyContent = "flex-start";
    if (showSaveButton && !showBackButton) {
      justifyContent = "flex-end";
    }
    if (showSaveButton && showBackButton) {
      justifyContent = "space-between";
    }
    return (
      <Box sx={{ display: "flex", justifyContent, width: "100%" }}>
        {showBackButton && (
          <Button
            onClick={onBackClick}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            back
          </Button>
        )}
        {showSaveButton && (
          <Button
            onClick={saveNewInfo}
            variant="contained"
            startIcon={<SaveIcon />}
            disabled={saveLoading}
          >
            Save
          </Button>
        )}
      </Box>
    );
  };
  // RENDER CONDITIONALS =================>
  if (!companyType) {
    return (
      <Box>
        {renderBackSaveButton(true, false)}
        <Typography
          sx={{ padding: "8px 0px", textAlign: "center" }}
          color="error"
        >
          missingCompanyType
        </Typography>
      </Box>
    );
  }
  // HANDLER FUNCTIONS =================>
  // Function to handle the Gics Code Change
  const handleGicsCodeChange = (event) => {
    setGicsCode(event.target.value);
  };
  // Function to feed the CSP Value
  const feedCspValue = (cspKey, value) => {

      setNewData({
        ...newData,
        [cspKey]: value,
      });

  };
  // Function to feed all values
  const feedAllValues = () => {
    if(reportData?.gics && reportData.gics.length > 1 && gicsCode === ""){
      toast.error("Bitte wählen Sie einen GICS-Code aus.");
      return;
    }
    setNewData({
      ...newData,
      nace_code_level_three: reportData?.nace_code_level_three,
      gics_code_level_three: !reportData?.gics || reportData.gics.length === 0
        ? null
        : reportData.gics.length === 1
        ? reportData.gics[0].code_level_three
        : gicsCode,
      name: reportData?.name,
      legalForm: reportData?.legalForm,
      hq_street: `${reportData?.hq_street} ${reportData?.houseNumber || ""}`,
      hq_zip_code: reportData?.hq_zip_code,
      hq_city: reportData?.hq_city,
      hq_country: reportData?.hq_country,
      website: reportData?.website,
      phone: reportData?.phone,
      taxId: reportData?.tax_id,
      local_court: reportData?.registerCourt,
      register_number: reportData?.register_number,
    });
  };
  // Function to save new info
  const saveNewInfo = async () => {
    setSaveLoading(true);
    try {
      const res = await crefoAPI.updateCompanyData(companyId, newData);
      if (res.status === 200) {
        toast.success("Die Firma wurde erfolgreich aktualisiert.");
        onBackClick();
        setSaveLoading(false);
      }
    } catch (err) {
      console.log(err);
      toast.error("Fehler beim aktualisieren der Firma. Bitte versuchen Sie es später erneut.");
      setSaveLoading(false);
    }
  };

  return (
    <Box>
      {renderBackSaveButton(true, true)}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{ padding: "8px 0px", textAlign: "center", marginRight: "1rem" }}
        >
          Info Message:
        </Typography>
        <Button
          onClick={feedAllValues}
          endIcon={<ArrowForwardIcon />}
          variant="outlined"
          size="small"
        >
          use all
        </Button>
      </Box>
      <TableContainer>
        <Table
          size="small"
          sx={{
            border: `1px solid ${colors.gridBorderColor}`,
            borderRadius: "10px",
            marginTop: "2rem",
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ width: "20%", fontWeight: "bold", color: "teal" }}
              >
                Field
              </TableCell>
              <TableCell
                sx={{ width: "40%", fontWeight: "bold", color: "teal" }}
              >
                Crefo Report
              </TableCell>
              <TableCell
                sx={{ width: "40%", fontWeight: "bold", color: "teal" }}
              >
                CSP
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {infoDiffRow(
              "nace_code_level_three",
              reportData?.nace_code_level_three
            )}
            {infoDiffRow("gics_code_level_three", reportData?.gics)}
            {infoDiffRow("name", reportData?.name)}
            {infoDiffRow("legalForm", reportData?.legalForm)}
            {infoDiffRow("hq_street", `${reportData?.hq_street} `)}
            {infoDiffRow("hq_zip_code", reportData?.hq_zip_code)}
            {infoDiffRow("hq_city", reportData?.hq_city)}
            {infoDiffRow("hq_country", reportData?.hq_country)}
            {infoDiffRow("website", reportData?.website)}
            {infoDiffRow("phone", reportData?.phone)}
            {infoDiffRow("taxId", reportData?.taxId)}
            {infoDiffRow("local_court", reportData?.local_court)}
            {infoDiffRow("register_number", reportData?.register_number)}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{ display: "flex", justifyContent: "flex-end", marginTop: "16px" }}
      >
        <Button
          variant="outlined"
          startIcon={<CancelIcon />}
          onClick={onBackClick}
        >
          close
        </Button>
      </Box>
    </Box>
  );
};

CrefoReportDataFiller.propTypes = {
  companyId: PropTypes.number.isRequired,
  companyType: PropTypes.string.isRequired,
  companyData: PropTypes.object.isRequired,
  reportData: PropTypes.object.isRequired,
  onBackClick: PropTypes.func,
};
