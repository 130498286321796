import { Box, IconButton, Tooltip } from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { toast } from "sonner";
import PropTypes from "prop-types";


export const formatDate = (customTimeStamp, timeStamp) => {
    if (customTimeStamp) {
        return customTimeStamp;
    }
    if (timeStamp) {
        const normalisedDate = new Date(timeStamp);
        const userTimezoneOffset = normalisedDate.getTimezoneOffset() * 60000;
        const localDate = new Date(normalisedDate.getTime() - userTimezoneOffset);
        return localDate.toLocaleString("en-US", {
            timeZone: 'UTC', // Specify the time zone
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    }
    return '';
};


export const formatContent = (content) => {
    // Check if content is null or undefined
    if (content == null) {
        return <Box />;
    }

    return content.split("\n").map((paragraph, idx) => (
        <Box key={`paragraph-${idx}`} sx={{ marginBottom: idx === content.split("\n").length - 1 ? "" : "10px" }}>
            {paragraph}
        </Box>
    ));
};



export const ChatBubble = ({
  content,
  isRight,
  name,
  timeStamp,
  ChatIcon,
  sources,
  isCopyable,
  customTimeStamp, // Optional prop for custom displayed timestamp
}) => {
  const citationColor = "#115b8f";
  const citationBgColor = "#bbe1fc";
  const displayedTimeStamp = formatDate(customTimeStamp, timeStamp);


  const renderCopyContent = () => {
    return (
      <Tooltip title={"Copy answer"} arrow>
        <IconButton
          sx={{ width: "21px", height: "21px" }}
          onClick={() => {
            toast.success("Copied");
            navigator.clipboard.writeText(content);
          }}
        >
          <ContentCopyRoundedIcon
            color="inherit"
            sx={{
              width: "18px",
              color: isRight ? "teal" : "gray",
              margin: !isRight ? "0 0 0 5px" : "0 5px 0 0",
            }}
          />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <>
      <style>
        {`
        .chat-bubble-left {
          animation: bubble-appear-left 0.5s ease;
        }
        .chat-bubble-right {
          animation: bubble-appear-right 0.5s ease;
        }

        @keyframes bubble-appear-left {
          0% {
            opacity: 0;
            transform: translateY(5px) scale(0.95) translateX(-2.5%);
          }
          20% {
            opacity: 0.9;
          }
          100% {
            opacity: 1;
            transform: translateY(0px) scale(1) translateX(0px);
          }
        }
        @keyframes bubble-appear-right {
          0% {
            opacity: 0;
            transform: translateY(5px) scale(0.95) translateX(2.5%);
          }
          20% {
            opacity: 0.9;
          }
          100% {
            opacity: 1;
            transform: translateY(0px) scale(1) translateX(0px);
          }
        }
      `}
      </style>
      <Box
        className={`chat-bubble-${isRight ? "right" : "left"}`}
        sx={{
          display: "flex",
          justifyContent: isRight ? "flex-end" : "flex-start",
          paddingBottom: "10px",
        }}
      >
        <Box sx={{ maxWidth: "800px" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: !isCopyable ? "flex-end" : "space-between",
              fontWeight: "bold",
              color: isRight ? "teal" : "gray",
              fontSize: "14px",
              paddingBottom: "3px",
            }}
          >
            {isRight && isCopyable && <>{renderCopyContent()}</>}
            <Box sx={{ display: "flex", gap: "3px" }}>
              {!isRight && ChatIcon && (
                <ChatIcon
                  sx={{ height: "21px", width: "21px" }}
                  data-testid="chat-icon"
                />
              )}
              {isRight && (timeStamp || customTimeStamp) && (
                <Box
                  sx={{
                    color: "gray",
                    fontSize: "12px",
                    fontWeight: "normal",
                    marginTop: "auto",
                    marginRight: "5px",
                  }}
                  data-testid="chat-timestamp"
                >
                  {displayedTimeStamp}
                </Box>
              )}
              {name}
              {!isRight && (timeStamp || customTimeStamp) && (
                <Box
                  sx={{
                    color: "gray",
                    fontSize: "12px",
                    fontWeight: "normal",
                    marginTop: "auto",
                    marginLeft: "5px",
                  }}
                  data-testid="chat-timestamp"
                >
                  {displayedTimeStamp}
                </Box>
              )}
              {isRight && ChatIcon && (
                <ChatIcon
                  color="inherit"
                  sx={{ color: "teal", height: "21px", width: "21px" }}
                  data-testid="chat-icon"
                />
              )}
            </Box>
            {!isRight && isCopyable && <>{renderCopyContent()}</>}
          </Box>
          <Box
            sx={{
              marginBottom: "10px",
              fontSize: "14px",
              padding: "10px 12px",
              backgroundColor: isRight ? "rgb(193, 242, 237)" : "#f5f5f5",
              marginLeft: isRight ? "auto" : "",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            <Box
              sx={{
                marginBottom: sources && sources.length !== 0 ? "7px" : "",
              }}
            >
              {formatContent(content)}
            </Box>
            {sources && sources.length !== 0 && (
              <Box
                sx={{
                  display: "flex",
                  gap: "4px",
                  overflowX: "scroll",
                }}
              >
                {sources.map((source) => {
                  return (
                    <Box
                      sx={{
                        color: citationColor,
                        backgroundColor: citationBgColor,
                        borderRadius: "5px",
                        padding: "2px 5px",
                        width: "fit-content",
                        display: "flex",
                      }}
                      key={"source-" + source.foot_note}
                    >
                      <Tooltip
                        title={source.citation}
                        arrow
                        data-testid="chat-source-tooltip"
                      >
                        <Box sx={{ marginRight: "4px", whiteSpace: "nowrap" }}>
                          {source.foot_note} {source.source}
                        </Box>
                      </Tooltip>

                      <Tooltip title={"Copy citation"} arrow>
                        <IconButton
                          sx={{ width: "21px", height: "21px" }}
                          onClick={() => {
                            toast.success("Copied");
                            navigator.clipboard.writeText(
                              source.citation_string
                            );
                          }}
                        >
                          <ContentCopyRoundedIcon
                            color="inherit"
                            sx={{ width: "18px", color: citationColor }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  );
                })}
              </Box>
            )}
            {/** Bubble Design */}
            {isRight ? (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-10px",
                  right: "0",
                  width: "20px",
                  height: "20px",
                  backgroundColor: "rgb(151, 219, 212)",
                  zIndex: "-1",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    width: "40px",
                    height: "20px",
                    backgroundColor: "white",
                    transform: "translate(-20%, 70%) rotate(24deg)",
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-10px",
                  left: "0",
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#e7e7e7",
                  zIndex: "-1",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    width: "40px",
                    height: "20px",
                    backgroundColor: "white",
                    transform: "translate(-20%, 70%) rotate(-24deg)",
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

ChatBubble.propTypes = {
    content: PropTypes.string.isRequired,
    isRight: PropTypes.bool,
    name: PropTypes.string.isRequired,
    timeStamp: PropTypes.string,
    ChatIcon: PropTypes.elementType,
    sources: PropTypes.array,
    isCopyable: PropTypes.bool,
    customTimeStamp: PropTypes.string,
}
