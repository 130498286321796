import { useQuery } from "@tanstack/react-query";
import {
  fetchForecastScenarioForecastTimeSlices,
  fetchForecastScenarioPositions,
  fetchForecastScenarios,
  fetchLiabilities,
  fetchTimeSlices,
  fetchPositions,
  fetchLoadConditionPositions,
  fetchAllCommentsByGroup,
} from "./api";

export const useFetchTimeSlices = (companyId) => {
  return useQuery({
    queryKey: ["timeSlices", companyId],
    queryFn: () => fetchTimeSlices(companyId),
  });
};

export const useFetchForecastScenario = (timeSliceId) => {
  return useQuery({
    queryKey: ["forecastScenarios", timeSliceId],
    queryFn: () => fetchForecastScenarios(timeSliceId),
  });
};

export const useFetchForecastScenarioPositions = (
  forecastScenarioId,
  positionType
) => {
  return useQuery({
    queryKey: ["forecastScenarioPositions", forecastScenarioId, positionType],
    queryFn: () =>
      fetchForecastScenarioPositions(forecastScenarioId, positionType),
  });
};

export const useFetchForecastScenarioForecastTimeSlices = (
  forecastScenarioId
) => {
  return useQuery({
    queryKey: ["forecastScenarioForecastTimeSlices", forecastScenarioId],
    queryFn: () => fetchForecastScenarioForecastTimeSlices(forecastScenarioId),
  });
};

export const useFetchPositionAttributes = (positionType = "") => {
  return useQuery({
    queryKey: ["fetchPositionAttributes", positionType],
    queryFn: () =>
      fetchPositions(positionType)
        .then((response) => response.data)
        .catch((error) => console.error(error)),
  });
};

export const useFetchLiabilities = (companyId) => {
  return useQuery({
    queryKey: ["liabilities", companyId],
    queryFn: () => fetchLiabilities(companyId),
  });
};

// LOAN CONDITIONS
export const useFetchPositionsForLoanCondition = () => {
  return useQuery({
    queryKey: ["loanConditionPositions"],
    queryFn: () => fetchLoadConditionPositions(),
  });
};

// COMMENTS
export const useFetchAllComments = (groupId, refetchComments) => {
  return useQuery({
    queryKey: ["fetchAllCommentsByGroup", groupId , refetchComments],
    queryFn: () => fetchAllCommentsByGroup(groupId),
  });
};
