import React from "react";
import {useFetchTranscript} from "./hooks";
import {ChatBubble} from "../Chat/ChatBubble";
import PersonIcon from '@mui/icons-material/Person';
import PropTypes from "prop-types";
const CallTranscript = ({ddCallId}) => {
  const {data: transcript, isLoading: isLoadTranscript} = useFetchTranscript(ddCallId);
  return (
    <div>
      {isLoadTranscript ? (
        <div>Loading...</div>
      ) : (
        <div>
          {transcript.map((qa) => (
            <ChatBubble key={qa.id} content={qa.content} isRight = {(qa.employee_id === null)}
            name={qa.speaker} customTimeStamp={qa.from_timestamp} ChatIcon={PersonIcon} isCopyable={true}
                        >

            </ChatBubble>
          ))}
        </div>
      )}
    </div>
  );
};

export default CallTranscript;

CallTranscript.propTypes = {
    ddCallId: PropTypes.string.isRequired,
}
